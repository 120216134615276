import React from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  imageIcon?: string
  hasBullet?: boolean
  isFirstLevel?: boolean
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  imageIcon,
  hasBullet = false,
  isFirstLevel = false,
}) => {

  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config

  return (
    <>
   
      <div className={clsx('menu-item', isFirstLevel ? 'py-2' : '', { here: isActive})} >
        <Link className={clsx('menu-link w-100', {active: isActive}, isFirstLevel ? 'menu-center' : '')} to={to}>

          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {fontIcon && (
            <span className= {clsx('menu-icon', isFirstLevel ? 'me-0' : '')}>
              <i className={clsx(fontIcon, isFirstLevel ? 'fs-2' : 'fs-3')}></i>
            </span>  
          )}     
          {icon && aside.menuIcon === 'svg' && (
            <span className='menu-icon'>
              <KTSVG path={icon} className='svg-icon-2' />
            </span>
          )} 
          {imageIcon && (
            <span className= {clsx('menu-icon', isFirstLevel ? 'me-0' : '')}>
              <img width="30" height="30" className={clsx(isActive ? 'opacity-100' : 'opacity-25')} src={imageIcon} />
            </span>  
          )}

          <span className="menu-title text-center">{title}</span>
        </Link>
      </div>                 
    </>

  )
}

export {AsideMenuItem}
