import { useNavigate, Link, useLocation, Outlet, useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import { KTSVG } from '../../../_metronic/helpers'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

import { useModuleActive } from '../../../hooks/useModuleActive';
import { MODULE_IDS } from '../../../models/systems/ModuleIds';

import { Spinner, Modal, Button } from 'react-bootstrap';
import { usePageData } from '../../../_metronic/layout/core';
import useAuth from "../../../hooks/useAuth";
import DatePicker, { registerLocale } from 'react-datepicker';
import DataTable from 'react-data-table-component';
import clsx from 'clsx'
import FileSaver from "file-saver";
import InfoTooltip from '../../system/generic/InfoTooltip';
import {UnitJobStatus} from '../unit/UnitComponents';
import Select from 'react-select';
import { normalizeEntity, getPayload, getPageMode } from "./ProdOrderComponents";
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

import {ProdOrderAiPreloadComponent} from './plugins/ProdOrderAIPreloadComponent'; 

const ProdOrderCreate = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const isModuleActive = useModuleActive();


    const [isError, setIsError] = useState();
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
    const [modalShow, setModalShow] = useState();
    const [modalMessage, setModalMessage] = useState();
    const [saveInProgress, setSaveInProgress] = useState();
    const [saveResult, setSaveResult] = useState();
    const [newWorkCycleViewModel, setNewWorkCycleViewModel] = useState();
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);

    const [deleteConfirmationModalShow, setDeleteConfirmationModalShow] = useState(false);
    const [deleteInProgress, setDeleteInProgress] = useState();

    const [isPdfInProgress, setIsPdfInProgress] = useState(false);
    const [operationInProgress, setOperationInProgress] = useState("");    

    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(0);
    const [rowId, setRowId] = useState(-1);

    const [isModuleQualityControlActive] = useState(isModuleActive(MODULE_IDS.QUANITTY_CONTROL));


    let { orderId } = useParams();
    let { handlerMode } = useParams();

    const { setPageTitle, pageMachines } = usePageData();
    const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();

    const isEditMode = () => {

        var output = false;

        if(location.pathname.indexOf("productionOrders/edit") > -1)
        {
            output = true;
        }
       
        return output;
    }

    const isViewMode = () => {

        var output = false;

        if(location.pathname.indexOf("productionOrders/view") > -1)
        {
            output = true;
        }
       
        return output;
    }    

    const isCreateMode = () => {

        var output = false;


        if(location.pathname.indexOf("productionOrders/create") > -1)
        {
            output = true;
        }
       
        return output;
    }   
    
    const getProdViewLink = (row, text) => {
        let rowId = row?.id;
        let machineId = row?.machineId;
        let unitId = row?.unitId;
    
        return <Link to={`/units/${unitId}/jobs/${machineId}/viewJob/${rowId}`} className='text-dark text-hover-primary fs-6'>{text}</Link>;    
      }       

    const conditionalRowStyles = [
        {
          when: row => row.unitName == "WAITING",
          style: {
            backgroundColor: '#E4E6EF'
          },
        },
        
      ];

    const columns = [
        {
            name: 'Reparto',
            selector: row => row.unitName,
            sortable: false,
            cell: (row, index, column, id) => {
                if (row.unitName != "WAITING") {
                    return row?.unitName;
                }
              }   
          },
        {
          name: 'Macchina',
          selector: row => row.machineName,
          sortable: false,
          cell: (row, index, column, id) => {
            if (row.unitName != "WAITING") {
                return row?.machineName;
            }
          }       
        },
        {
            name: 'Num. Pelli',
            selector: row => row.leathersNumber,
            sortable: false,
            cell: (row, index, column, id) => {
                if (row.unitName != "WAITING") {
                    return <>
                        {isModuleQualityControlActive ? <>

                            {row.quantityWarning ? <>

                                {row?.leathersNumber}
                                <i className="text-danger fas fa-exclamation-triangle ms-2 fs-3"></i>
                            </> :
                                <>
                                    {row?.leathersNumber}
                                </>}
                        </>
                            :
                            <>
                                {row?.leathersNumber}
                            </>
                        }
                    
                    </>;
                }
            }     
          }, 
        {
          name: 'Stato',
          selector: row => row.status,
          maxWidth: '180px',
          sortable: false,
          cell: (row, index, column, id) => {

            if (row.unitName != "WAITING") {

                var bgClass = '';
                var statusName = '';
        
                if (row.startDate != null && row.endDate == null) {
                  bgClass = 'bg-warning';
                  statusName = 'In Corso';
                }
                else if (row.endDate != null) {
                  bgClass = 'bg-success';
                  statusName = 'Completo';
                }
        
                return (<span className={clsx(`badge ${bgClass}`)}>{statusName}</span>);
            }
          }
        },
        {
            name: 'Durata',
            selector: row => row.durationStr,
            maxWidth: '150px',
            sortable: false,
            cell: (row, index, column, id) => 
            {
                return row?.durationStr;
            } 
          },
        {
            name: 'Data Inizio',
            selector: row => row.startDateStr,
            maxWidth: '160px',
            sortable: false,
            cell: (row, index, column, id) => {
                if (row.unitName != "WAITING") {
                    return row?.startDateShortStr;
                }
            }
        },
        {
          name: 'Data Fine',
          selector: row => row.endDateStr,
          maxWidth: '160px',
          sortable: false,
          cell: (row, index, column, id) => {
            if (row.unitName != "WAITING") {
                return row?.endDateShortStr;
            }
          } 
        },
        {
            name: 'Note',
            selector: row => row.notes,
            maxWidth: '30px',
            sortable: false,
            cell: (row, index, column, id) => {
                if (row.unitName != "WAITING" && row.hasNotes) {
                    return (<InfoTooltip text={row?.notes}></InfoTooltip>)
                }
            }
        },
        {
            name: '',
            maxWidth: '100px',
            right: true,
            ignoreRowClick: true,
            cell: (row, index, column, id) => {

                if (row.unitName != "WAITING") {
                    return (
                        <div className='row'>

                            <Link to={`/units/${row?.unitId}/jobs/${row?.machineId}/viewJob/${row?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                <i className="fas fa-eye"></i>
                            </Link>
                        </div>)
                }
            }
        }
    ];

    const inventoryMovementsColumns = [
        {
            name: 'Magazzino',
            selector: row => row.inventoryId,
            maxWidth: '120px',
            sortable: false,
            cell: (row, index, column, id) => {
                if (row?.inventoryId == 0) {
                    return "Piclato";
                }
                else if (row?.inventoryId == 1) {
                    return "Crust";
                }
                else if (row?.inventoryId == 2) {
                    return "WB";
                }
                else if (row?.inventoryId == 2) {
                    return "Finito";
                }
            }
        },
        {
            name: 'Data',
            maxWidth: '200px',
            selector: row => row.date,
            sortable: false,
            cell: (row, index, column, id) => {
                return row?.dateStr
            }
        },
        {
            name: 'Tipo',
            maxWidth: '80px',
            selector: row => row.type,
            sortable: false,
            cell: (row, index, column, id) => {

                var bgClass = '';

                if (row?.type == "LOAD") {
                    bgClass = 'bg-success';
                }
                else if (row?.type == "UNLOAD") {
                    bgClass = 'bg-warning';
                }

                return (<span className={clsx(`badge ${bgClass}`)}>{row?.typeStr}</span>);
            }
        },
        {
            name: 'Lotto',
            maxWidth: '150px',
            selector: row => row.batchNumber,
            sortable: false,
            cell: (row, index, column, id) => {
                return (<><div title={row?.batchNumber}>{row?.batchNumber}</div></>)
            }
        },
        {
            name: 'Bancale',
            maxWidth: '100px',
            title: 'Hello World!',
            selector: row => row.palletNumber,
            sortable: false,
            cell: (row, index, column, id) => {
                return (<><div title={row?.palletNumber}>{row?.palletNumber}</div></>)
            }
        },
        {
            name: 'Articolo',
            selector: row => row.inventoryArticleFullName,
            sortable: false
        },
        {
            name: 'Num. Pelli',
            maxWidth: '120px',
            selector: row => row.quantityStr,
            sortable: false,
        },
        {
            name: 'Note',
            maxWidth: '200px',
            selector: row => row.notes,
            sortable: false,
            cell: (row, index, column, id) => {
                return (<><div title={row?.notes}>{row?.notes}</div></>)
            }
        }
    ];


    const customerOrdersColumns = [
        {
          name: 'Articolo',
          selector: row => row.articleName,
          sortable: false,    
          cell: (row, index, column, id) => {
            return (
                <input type="text"
                    className={clsx('form-control', {
                        'bg-light-danger border-danger': row?.articleName.isError,
                        'bg-ai-10': row?.articleName?.isAi,
                      })}
                    
                    placeholder=""
                    value={row?.articleName.value}
                    autoComplete="off"
                    onChange={(e) => setRowPropertyValue(row, 'articleName', e.target.value, e)}
                    {...(isViewMode(location)) && {disabled: true}}
                />)       
          }
        }, 
        {
          name: 'Cliente',
          selector: row => row.customerCode,
          sortable: false,
          cell: (row, index, column, id) => {
            return (
                <input type="text"
                    className={clsx('form-control', {
                        'bg-light-danger border-danger': row?.customerCode.isError,
                        'bg-ai-10': row?.customerCode?.isAi,
                      })}

                    placeholder=""
                    value={row?.customerCode.value}
                    autoComplete="off"
                    onChange={(e) => setRowPropertyValue(row, 'customerCode', e.target.value, e)}
                    {...(isViewMode(location)) && {disabled: true}}
                />)          
          }
          
        }, 
        {
          name: 'Colore',
          selector: row => row.articleColor,
          sortable: false,
          cell: (row, index, column, id) => {
            return (
                <input type="text"
                    className={clsx('form-control', {
                        'bg-light-danger border-danger': row?.articleColor.isError,
                        'bg-ai-10': row?.articleColor?.isAi,
                      })}
                    
                    placeholder=""
                    value={row?.articleColor.value}
                    autoComplete="off"
                    onChange={(e) => setRowPropertyValue(row, 'articleColor', e.target.value, e)}
                    {...(isViewMode(location)) && {disabled: true}}
                />)          
          }
        }, 
        {
          name: 'Data Consegna Ordine',
          selector: row => row.deliveryDate,
          sortable: false,
          cell: (row, index, column, id) => {
            return (<>
             <input type="text"
                    
                    className={clsx('form-control', {
                        'bg-light-danger border-danger': row?.deliveryDate.isError,
                        'bg-ai-10': row?.deliveryDate?.isAi,
                      })}

                    placeholder=""
                    value={row?.deliveryDate.value}
                    autoComplete="off"
                    onChange={(e) => setRowPropertyValue(row, 'deliveryDate', e.target.value, e)}
                    {...(isViewMode(location)) && {disabled: true}}
                />
            </>
               )          
          }
        }, 
        {
          name: 'Quantità (MQ)',
          selector: row => row.quantity,
          sortable: false,
          cell: (row, index, column, id) => {
            return (
                <input type="text"
                    className={clsx('form-control', {
                        'bg-light-danger border-danger': row?.quantity.isError,
                        'bg-ai-10': row?.quantity?.isAi,
                      })}

                    placeholder=""
                    pattern="[0-9]*([,][0-9]{0,5})?"
                    value={row?.quantity.value}
                    autoComplete="off"
                    onChange={(e) => setRowPropertyValue(row, 'quantity', e.target.value, e)}
                    {...(isViewMode(location)) && {disabled: true}}
                />)          
          }
        }
      ];

      const onSelectedRowsChange = (state) => {

        var selectedRowId = 0;
    
        state.selectedRows.forEach(function (tableRow) {
            selectedRowId = tableRow.id;
        });

        setSelectedRowId(selectedRowId);
      };  


      const setRowPropertyValue = (row, propertyCode, value, event) => {

        if(event.target.validity.valid)
        {
            var wc = { ...newWorkCycleViewModel };

            wc.customerOrderRows.forEach(function(tableRow) {
    
                if(tableRow.id == row.id)
                {  
                    tableRow[propertyCode].value = value;
                    tableRow[propertyCode].isAi = false;
                }
            }); 
            
            setNewWorkCycleViewModel(wc);
        }
    } 

    const addCustomerOrderRow = () => {
        var wc = { ...newWorkCycleViewModel };

        let newRow = getNewEmptyRow(wc);
        console.log(newRow)

        wc.customerOrderRows.push(newRow);

        //Ricerca della riga selezionata
        setNewWorkCycleViewModel(wc);
    }

    //Rimuove il box selezionato
    const removeCustomerOrderRow = () => {

        console.log("selectedRowId", selectedRowId)
        if(selectedRowId != 0)
        {
            var wc = { ...newWorkCycleViewModel };

            var index = 0;
            var selectedIndex = 0;

            wc.customerOrderRows.forEach(function(tableRow) {
        
                if(tableRow.id == selectedRowId)
                {  
                    selectedIndex = index;
                }

                index++;
            });

            wc.customerOrderRows.splice(selectedIndex, 1);

            clearSelection();
        }        
    }

    const clearSelection = (wc) => {
        setClearSelectedRows(!clearSelectedRows);
        setSelectedRowId(0);
    }

    const getNewEmptyRow = (wc) => {

        let output = JSON.parse(JSON.stringify(wc.emptyCustomerOrderRowViewModel));
        //Aggiunta di un prefisso per evitare che vada in conflitto con eventuali id numerici già esistenti
        output.id = rowId;

        setRowId(rowId-1);

        return output;
    }

    const handleClose = () => {
        if (saveResult == true) {
            if(operationInProgress == "delete")
            {
                navigate(`/productionOrders`);
            }
            else
            {
                navigate(`/productionOrders/view/${newWorkCycleViewModel.id}`);
            }
        }

        setModalShow(false);
    }

    const handleConfirmationClose = () => {
        setConfirmationModalShow(false);
    }

    const handleDeleteConfirmationClose = () => {
        setDeleteConfirmationModalShow(false);
    }        

    const confirmCreate = async () => {
        setConfirmationModalShow(false);

        //Se c'è un salvataggio in corso non possono essere inoltrare ulteriori richieste
        if (saveInProgress) {
            return;
        }

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors() == true) {
            return;
        }

        setLoadingSave(true);
        setSaveInProgress(true);

        //Impostazione dell'id macchina per cui creare il ciclo di lavoro

        var endpoint = "";

        if(isEditMode())
        {
            endpoint = `/production/editProductionOrder`;
        }
        else
        {
            endpoint = `/production/createProductionOrder`;
        }

        let payload = getPayload(newWorkCycleViewModel);

        try {
            const response = await axiosPrivate.post(endpoint,
                JSON.stringify(payload),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );

            setNewWorkCycleViewModel({ ...newWorkCycleViewModel, id: response.data });

            setModalMessage("Operazione avvenuta con successo");
            setLoadingSave(false);
            setSaveInProgress(false);
            setSaveResult(true);
            setModalShow(true);
        }
        catch (err) {
            console.error(err);
            setSaveResult(false);
            setModalMessage(`${err}<br>${err?.response?.data?.message}`);
            setLoadingSave(false);
            setSaveInProgress(false);
            setModalShow(true);

            //navigate('/login', {state : {from: location }, replace: true});

            // if(err.response?.status === 403) 
            // {
            //     navigate('/login', {state : {from: location }, replace: true});
            // }
        }
    }

    const validateProperty = (property) => {

        //Validazione
        if (property.isMandatory) {
            var propValue = "";

            if (property.dataType == "Date") {
                propValue = property.value;
            }
            else if (property.dataType == "Select") {
                if (property.value != null) {
                    propValue = property.value.value;
                }
            }
            else {
                propValue = property.value?.trim();
            }

            if (propValue == "" || propValue == null) {
                property.isError = true;
                property.errorMessage = "Il campo non può essere vuoto";
            }
            else {
                property.isError = false;
                property.errorMessage = "";
            }
        }

        return property;
    }

    //Validazione completa dell'oggetto Lavorazione
    const validateWorkCycle = (newWorkCycle) => {

        newWorkCycle.orderNumber = validateProperty(newWorkCycle.orderNumber);
        newWorkCycle.customer = validateProperty(newWorkCycle.customer);
        newWorkCycle.articleId = validateProperty(newWorkCycle.articleId);
        newWorkCycle.article = validateProperty(newWorkCycle.article);
        newWorkCycle.color = validateProperty(newWorkCycle.color);
        newWorkCycle.leathersNumber = validateProperty(newWorkCycle.leathersNumber);

        return newWorkCycle;
    }

    const checkValidationErrors = () => {

        var output = false;

        if(newWorkCycleViewModel.orderNumber.isError
        || newWorkCycleViewModel.customer.isError
        || newWorkCycleViewModel.articleId.isError
        || newWorkCycleViewModel.article.isError
        || newWorkCycleViewModel.color.isError
        || newWorkCycleViewModel.leathersNumber.isError
        )
        {
            output = true;
        }

        return output;
    }

    const setPropertyValue = (propertyCode, value, event) => {

        if (propertyCode == "dataOrdine" || propertyCode == "dataConsegna" || propertyCode == "articleId" || event.target.validity.valid) {
            var wc = { ...newWorkCycleViewModel };

            wc[propertyCode].value = value;
            wc[propertyCode].isAi = false;
            wc[propertyCode] = validateProperty(wc[propertyCode]);

            //Quando viene scelto l'articolo dall'elenco, vengono popolati anche i relativi campi
            if (propertyCode == "articleId") {
                let articleCode = '';
                let articleName = '';
                let colorName = '';

                if (value != null) {

                    if (!(typeof value.articleCode === 'undefined')) {
                        articleCode = value.articleCode;
                    }

                    if (!(typeof value.articleName === 'undefined')) {
                        articleName = value.articleName;
                    }

                    if (!(typeof value.colorName === 'undefined')) {
                        colorName = value.colorName;
                    }
                }

                wc['articleCode'].value = articleCode;
                wc['articleCode'] = validateProperty(wc['articleCode']);

                wc['article'].value = articleName;
                wc['article'] = validateProperty(wc['article']);

                wc['color'].value = colorName;
                wc['color'] = validateProperty(wc['color']);
            }

            setNewWorkCycleViewModel(wc);
        }
    }

    const setLoadingSave = (isLoading) => {
        if (isLoading) {
            setShowIndicatorProgress('on');
        }
        else {
            setShowIndicatorProgress('off');
        }
    }

    const confirmDelete = async () => 
        {
            setDeleteConfirmationModalShow(false);
    
            //Se c'è un operazione in corso non possono essere inoltrare ulteriori richieste
            if(deleteInProgress)
            {
                return;
            }
    
            setDeleteInProgress(true);
            setOperationInProgress("delete");
    
            //Impostazione dell'id macchina per cui creare il ciclo di lavoro
            
            try {
                
                const response = await axiosPrivate.get(`/production/deleteProductionOrder/${orderId}`, 
                    {
                        headers: {'Content-Type':'application/json'},
                        withCredentials: true,
                    }
                );
    
                setModalMessage("Operazione avvenuta con successo");
                setDeleteInProgress(false);
                setSaveResult(true);
                setModalShow(true);
            }
            catch(err) 
            {
                setSaveResult(false);
                setModalMessage(`${err?.response?.data?.message}`);
                setDeleteInProgress(false);
                setModalShow(true);
            }           
        }      

    //Setting Page BreadCrumb
    useEffect(() => {

        setPageTitle("Cartellini");
        
        var breadcrumb = "Nuovo Cartellino";
        
        if(isEditMode())
        {
            breadcrumb = `Modifica Cartellino N. ${orderId}`;
        }
        else if(isViewMode())
        {
            breadcrumb = `Dettaglio Cartellino N. ${orderId}`;
        }

        setPageBreadcrumbs([breadcrumb]);
    }, [orderId, handlerMode])

    useEffect(() => {

        setIsLoading(true);

        let isMounted = true;
        const controller = new AbortController();

        const getNewWorkCycleModel = async () => {

            try {

                var entityId;

                //Se si è in modalità di modifica viene letto l'id del packing list interessato
                if(typeof orderId  === 'undefined')
                {
                    entityId = 0;
                }
                else
                {
                    entityId = orderId;
                }

                const response = await axiosPrivate.get(`/production/getProductionOrderModel/${entityId}`, 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,                    
                    signal: controller.signal
                });

                if (isMounted) 
                {
                    response.data.createdBy = auth.userId;

                    response.data = normalizeEntity(response.data);

                    //Validazione iniziale
                    validateWorkCycle(response.data);

                    setNewWorkCycleViewModel(response.data);

                    setIsLoading(false);
                    setIsError(false);
                }
            }
            catch (err) {
                console.error(err);
                setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
                setIsLoading(false);
                setIsError(true);

                // if(err.response?.status === 403) 
                // {
                //     navigate('/login', {state : {from: location }, replace: true});
                // } 
            }
        }

        getNewWorkCycleModel();

    }, [orderId, handlerMode])


    const handleSubmit = (e) => {

        e.preventDefault();

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors() == true) {
            return;
        }

        setConfirmationModalShow(true);
    }

    const downloadPdf = () => {

        if(isPdfInProgress)
        {
          return;
        }

        setIsPdfInProgress(true);

        console.log(isPdfInProgress);

        let isMounted = true;
        const controller = new AbortController();
    
        const getPdf = () => {

                axiosPrivate.get(`/production/downloadPdf/${orderId}`,
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                    responseType: "blob", // important!
                    signal: controller.signal
                })
                .then(res => {

                    let fileName = `ProductionOrder_${orderId}.pdf`;

                    const blob = new Blob([res.data], {
                        type: 'application/pdf'
                    })

                    FileSaver.saveAs(blob, fileName);
                    setModalMessage("");
                    setSaveResult(true);
                    setIsPdfInProgress(false);
                })
                .catch(err => {
                    console.log("ERR:",err.response);
                    setModalMessage(err?.response?.statusText);
                    setSaveResult(false);
                    setModalShow(true);
                    setIsPdfInProgress(false);
                });
        }

        getPdf();
    
        return () => {
          isMounted = false;
          controller.abort();
        }
    
       }

       const deleteEntity = () => 
       {
           setDeleteConfirmationModalShow(true);
       }  

    return (
        <>
            <div id="kt_content_container" className="mx-5">

                {/* begin::Spinner */}
                {isLoading &&
                    <div className="row d-flex justify-content-center m-20">
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
                {/* end::Spinner */}

                {/* begin::Error message */}
                {!isLoading && isError &&

                    <div className="row d-flex justify-content-center m-20">

                        <div className="alert alert-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">

                            <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                    <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                </svg>
                            </span>

                            <div className="text-center">
                                <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                            </div>
                        </div>
                    </div>

                }
                {/* end::Error message */}

                {!isLoading && !isError &&

                    <form
                        className="form w-100"
                        noValidate="novalidate"
                        id="kt_sign_in_form"
                        onSubmit={handleSubmit}>

                        {/* Sezione comune a tutte le macchine */}

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                        {isEditMode() && <>Modifica Cartellino - Intestazione</>}
                                        {isViewMode() && <>Dettaglio Cartellino - Intestazione</>}
                                        {isCreateMode() && <>Nuovo Cartellino - Intestazione</>}
                                    </span>
                                </h3>

                                {isModuleActive(MODULE_IDS.PRELOAD_PROD_ORDER) && <>

                                    {(isCreateMode() || isEditMode()) && <>
                                        <ProdOrderAiPreloadComponent
                                            entity={newWorkCycleViewModel}
                                            setEntity={setNewWorkCycleViewModel}
                                            validateProperty={validateProperty}
                                        />
                                    </>
                                    }

                                </>}

                            </div>

                            <div className='card-body py-3'>

                                <div className="row">

                                    <div className="mb-10 col-12 col-md-2">
                                        <label className="form-label">Numero Cartellino</label>
                                        <input type="text"
                                            className={clsx('form-control', {
                                                'bg-light-danger border-danger': newWorkCycleViewModel?.orderNumber.isError,
                                                'bg-ai-10': newWorkCycleViewModel?.orderNumber.isAi,
                                              })}

                                            placeholder=""
                                            value={newWorkCycleViewModel?.orderNumber.value}
                                            title={newWorkCycleViewModel?.orderNumber.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('orderNumber', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Numero Ordine Vendita</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.salesOrderNumber.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.salesOrderNumber.value}
                                            title={newWorkCycleViewModel?.salesOrderNumber.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('salesOrderNumber', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-2">
                                        <label className="form-label">Data</label>

                                        <DatePicker
                                            placeholderText="Scegli Data"
                                            locale="it"
                                            className={clsx(
                                                'form-control form-control-solid',
                                                {
                                                  'bg-light-danger border-danger': newWorkCycleViewModel?.dataOrdine?.isError,
                                                  'bg-ai-10': newWorkCycleViewModel?.dataOrdine?.isAi,
                                                }
                                              )}

                                            autoComplete="off"

                                            name="data"
                                            dateFormat="dd/MM/yyyy"

                                            selected={newWorkCycleViewModel?.dataOrdine.value}
                                            onChange={(date) => setPropertyValue('dataOrdine', date, null)}
                                            {...(isViewMode()) && {disabled: true}}
                                        />

                                    </div>

                                    <div className="mb-10 col-12 col-md-2">
                                        <label className="form-label">Data Consegna</label>
                                        
                                        <DatePicker
                                            placeholderText="Scegli Data"
                                            locale="it"
                                            className={clsx('form-control form-control-solid', newWorkCycleViewModel?.dataConsegna.isError ? 'bg-light-danger border-danger' : '')}
                                            autoComplete="off"
                                            name="data"
                                            dateFormat="dd/MM/yyyy"

                                            selected={newWorkCycleViewModel?.dataConsegna.value}
                                            onChange={(date) => setPropertyValue('dataConsegna', date, null)}
                                            {...(isViewMode()) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Stato</label>
                                        <select
                                            className='form-select'
                                            value={newWorkCycleViewModel?.status.value}
                                            title={newWorkCycleViewModel?.status.errorMessage}
                                            onChange={(e) => setPropertyValue('status', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}>
                                            <option value='0'>Scegli</option>
                                            <option value='1'>Da Fare</option>
                                            <option value='2'>In Produzione</option>
                                            <option value='3'>Completato</option>
                                        </select>
                                    </div>

                                    <div className="mb-10 col-12 col-md-5">
                                        <label className="form-label">Cliente</label>
                                        <input type="text"
                                            className={clsx('form-control', {
                                                'bg-light-danger border-danger': newWorkCycleViewModel?.customer?.isError,
                                                'bg-ai-10': newWorkCycleViewModel?.customer?.isAi,
                                              })}

                                            placeholder=""
                                            value={newWorkCycleViewModel?.customer.value}
                                            title={newWorkCycleViewModel?.customer.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('customer', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-2">
                                        <label className="form-label">Tipo Ordine</label>
                                        <select
                                            className={clsx('form-select', newWorkCycleViewModel?.orderType.isError ? 'bg-light-danger border-danger' : '')}
                                            value={newWorkCycleViewModel?.orderType.value}
                                            title={newWorkCycleViewModel?.orderType.errorMessage}
                                            onChange={(e) => setPropertyValue('orderType', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}>
                                            <option value=''>Scegli</option>
                                            <option value='Campionario'>Campionario</option>
                                            <option value='Produzione'>Produzione</option>
                                            <option value='Altro'>Altro</option>
                                        </select>
                                    </div>     

                                    <div className="mb-10 col-12 col-md-2">
                                        <label className="form-label">Destinazione d'uso</label>
                                        <select
                                            className='form-select'
                                            value={newWorkCycleViewModel?.tipoSemilavorato.value}
                                            title={newWorkCycleViewModel?.tipoSemilavorato.errorMessage}
                                            onChange={(e) => setPropertyValue('tipoSemilavorato', e.target.value, e)}
                                            {...(isViewMode()) && { disabled: true }}>
                                            <option value=''>Scegli</option>
                                            <option value='Calzatura'>Calzatura</option>
                                            <option value='Abbigliamento'>Abbigliamento</option>
                                            <option value='Pelletteria'>Pelletteria</option>
                                            <option value='Altro'>Altro</option>
                                        </select>
                                    </div>                                                                    

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Quantità</label>

                                        <div className="input-group mb-3">
                                            <select 
                                                className={clsx('form-select', {
                                                    'bg-light-danger border-danger': newWorkCycleViewModel?.quantityUM?.isError,
                                                    'bg-ai-10': newWorkCycleViewModel?.quantityUM?.isAi,
                                                  })}
                                               
                                               
                                                value={newWorkCycleViewModel?.quantityUM.value}
                                                onChange={(e) => setPropertyValue('quantityUM', e.target.value, e)}
                                                {...(isViewMode()) && {disabled: true}}>
                                                <option value="">Scegli</option>
                                                <option value="PQ">PQ</option>
                                                <option value="MQ">MQ</option>
                                                <option value="NR">NR</option>
                                            </select>

                                            <input type="text"
                                                className={clsx('form-control', {
                                                    'bg-light-danger border-danger': newWorkCycleViewModel?.piediQuadri?.isError,
                                                    'bg-ai-10': newWorkCycleViewModel?.piediQuadri?.isAi,
                                                  })}

                                                placeholder=""
                                                pattern="[0-9]*([,][0-9]{0,5})?"
                                                value={newWorkCycleViewModel?.piediQuadri.value}
                                                title={newWorkCycleViewModel?.piediQuadri.errorMessage}
                                                autoComplete="off"
                                                onChange={(e) => setPropertyValue('piediQuadri', e.target.value, e)}
                                                {...(isViewMode()) && { disabled: true }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                        Dettagli Articolo
                                    </span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>

                                <div className='row'>

                                    <div className='mb-10 col-12 col-md-4'>
                                        <label className="form-label">Articolo</label>
                                        <Select

                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: newWorkCycleViewModel?.articleId.isError ? 'red' : newWorkCycleViewModel?.articleId.isAi ? 'rgba(114, 57, 234)' : 'grey',
                                                    backgroundColor: newWorkCycleViewModel?.articleId.isError ? '#FFF5F8' : newWorkCycleViewModel?.articleId.isAi ? 'rgba(114, 57, 234, 0.1)' :'none',
                                                }),
                                            }}

                                            isClearable={true}
                                            options={newWorkCycleViewModel?.articles}
                                            isSearchable={true}
                                            value={newWorkCycleViewModel?.articleId.value}
                                            onChange={(selectedOption) => setPropertyValue('articleId', selectedOption)}
                                            isDisabled={isViewMode(location)}
                                        />
                                    </div>                                    

                                    <div className="mb-10 col-12 col-md-2">
                                        <label className="form-label">Codice Articolo</label>
                                        <input type="text"
                                            className={clsx('form-control', {
                                                'bg-light-danger border-danger': newWorkCycleViewModel?.articleCode?.isError,
                                                'bg-ai-10': newWorkCycleViewModel?.articleCode?.isAi,
                                              })}
                                            
                                            placeholder=""
                                            value={newWorkCycleViewModel?.articleCode.value}
                                            title={newWorkCycleViewModel?.articleCode.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('articleCode', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Nome Articolo</label>
                                        <input type="text"
                                            className={clsx('form-control', {
                                                'bg-light-danger border-danger': newWorkCycleViewModel?.article?.isError,
                                                'bg-ai-10': newWorkCycleViewModel?.article?.isAi,
                                              })}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.article.value}
                                            title={newWorkCycleViewModel?.article.errorMessage}
                                            onChange={(e) => setPropertyValue('article', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Colore</label>
                                        <input type="text"
                                            className={clsx('form-control', {
                                                'bg-light-danger border-danger': newWorkCycleViewModel?.color?.isError,
                                                'bg-ai-10': newWorkCycleViewModel?.color?.isAi,
                                              })}
                                            
                                            placeholder=""
                                            value={newWorkCycleViewModel?.color.value}
                                            title={newWorkCycleViewModel?.color.errorMessage}
                                            onChange={(e) => setPropertyValue('color', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                        Dettagli Selezione
                                    </span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>

                                <div className='row'>

                                <div className="mb-10 col-12 col-md-6">
                                    <label className="form-label">Numero Lotto</label>
                                        <textarea
                                            className={clsx('form-control', {
                                                'bg-light-danger border-danger': newWorkCycleViewModel?.numeroLotto?.isError,
                                                'bg-ai-10': newWorkCycleViewModel?.numeroLotto?.isAi,
                                              })}

                                            placeholder=""
                                            value={newWorkCycleViewModel?.numeroLotto.value}
                                            title={newWorkCycleViewModel?.numeroLotto.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('numeroLotto', e.target.value, e)}
                                            {...(isViewMode()) && { disabled: true }}
                                            rows="6"
                                        />

                                </div>

                                    <div className="mb-10 col-12 col-md-6">

                                        <div className="row">

                                            <div 
                                                className={clsx('mb-10 col-12',
                                                 isModuleActive(MODULE_IDS.QUANITTY_CONTROL) && ( !isCreateMode()) ? 'col-md-6':'col-md-12') //Se il modulo controllo qualità è attivo il campo cambia dimensione in base alla modalità
                                                 }> 


                                                <label className="form-label">Numero Pelli</label>
                                                <input type="text"
                                                    className={clsx('form-control', {
                                                        'bg-light-danger border-danger': newWorkCycleViewModel?.leathersNumber?.isError,
                                                        'bg-ai-10': newWorkCycleViewModel?.leathersNumber?.isAi,
                                                    })}

                                                    placeholder=""
                                                    value={newWorkCycleViewModel?.leathersNumber.value}
                                                    title={newWorkCycleViewModel?.leathersNumber.errorMessage}
                                                    autoComplete="off"
                                                    onChange={(e) => setPropertyValue('leathersNumber', e.target.value, e)}
                                                    {...(isViewMode()) && { disabled: true }}
                                                />
                                            </div>

                                            <div 

                                                className={clsx('mb-10 col-12 col-md-6',
                                                    !isModuleActive(MODULE_IDS.QUANITTY_CONTROL)? 'd-none':'', //Nasconde il campo se il modulo controllo qualità non è attivo (Non verrà mai mostrato)
                                                    isCreateMode()? 'd-none':'' //Nasconde il campo se si è in modalità di creazione (valido solo quando il modulo è attivo, vedi condizione precedente)
                                                    )}>

                                                <InfoTooltip text="Indica la quantità dell'ultima lavorazione registrata"></InfoTooltip>
                                         
                                                <label className="form-label">
                                                    Ultimo Numero Pelli Registrato
                                                    </label>
                                                

                                                <div className="input-group">

                                                    {newWorkCycleViewModel?.quantityControlWarning && <>
                                                        <span
                                                            className="input-group-text border-danger border-end-0">

                                                            <OverlayTrigger
                                                                placement='top'
                                                                overlay={<Tooltip>Attenzione! Ultima quantità registrata differente da quella desiderata</Tooltip>}>
                                                                <i className="text-danger fas fa-exclamation-triangle ms-2 fs-3"></i>
                                                            </OverlayTrigger>
                                                        </span>
                                                    </>}

                                                    <input type="text"
                                                        className={clsx('form-control',
                                                            newWorkCycleViewModel?.quantityControlWarning ? 'border-danger':'')}
                                                            
                                                        value={newWorkCycleViewModel?.lastLeathersNumber.value}
                                                        autoComplete="off"
                                                        disabled="disabled"
                                                    />
                                                
                                                </div>


                                            </div>

                                            <div className="mb-10 col-12">
                                                <label className="form-label">Spessore</label>
                                                <input type="text"
                                                    className={clsx('form-control', {
                                                        'bg-light-danger border-danger': newWorkCycleViewModel?.spessore?.isError,
                                                        'bg-ai-10': newWorkCycleViewModel?.spessore?.isAi,
                                                    })}

                                                    placeholder=""
                                                    value={newWorkCycleViewModel?.spessore.value}
                                                    title={newWorkCycleViewModel?.spessore.errorMessage}
                                                    onChange={(e) => setPropertyValue('spessore', e.target.value, e)}
                                                    {...(isViewMode()) && { disabled: true }}
                                                />
                                            </div>

                                        </div>

                                    </div>
{/* 
                                    <div className="mb-10 col-12 col-md-6">
                                        <label className="form-label">Numero Lotto</label>
                                        <textarea
                                            className={clsx('form-control', newWorkCycleViewModel?.numeroLotto.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.numeroLotto.value}
                                            title={newWorkCycleViewModel?.numeroLotto.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('numeroLotto', e.target.value, e)}
                                            {...(isViewMode()) && { disabled: true }}
                                            rows="3"
                                        />
                                    </div> */}





                                    {/* <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Numero Pelli</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.leathersNumber.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.leathersNumber.value}
                                            title={newWorkCycleViewModel?.leathersNumber.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('leathersNumber', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-4">
                                        <label className="form-label">Spessore</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.spessore.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.spessore.value}
                                            title={newWorkCycleViewModel?.spessore.errorMessage}
                                            onChange={(e) => setPropertyValue('spessore', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}
                                        />
                                    </div>                                     */}

                                    {/* <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Genere Grezzo</label>
                                        <select
                                            className='form-select'
                                            value={newWorkCycleViewModel?.genereGrezzo.value}
                                            title={newWorkCycleViewModel?.genereGrezzo.errorMessage}
                                            onChange={(e) => setPropertyValue('genereGrezzo', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}>
                                            <option value=''>Scegli</option>
                                            <option value='Altro'>Altro</option>
                                        </select>
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Tipo Pelle</label>
                                        <select
                                            className='form-select'
                                            value={newWorkCycleViewModel?.tipoPelle.value}
                                            title={newWorkCycleViewModel?.tipoPelle.errorMessage}
                                            onChange={(e) => setPropertyValue('tipoPelle', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}>
                                            <option value=''>Scegli</option>
                                            <option value='Altro'>Altro</option>
                                        </select>
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Peso</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.peso.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*([,][0-9]{0,5})?"
                                            value={newWorkCycleViewModel?.peso.value}
                                            title={newWorkCycleViewModel?.peso.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('peso', e.target.value, e)}
                                            {...(isViewMode()) && {disabled: true}}
                                        />
                                    </div> */}

                                </div>

                            </div>
                        </div>

                        {isModuleActive(MODULE_IDS.CUSTOMER_ORDER_LITE) && <>
                            <div className='card mb-5 mb-xl-8 mt-5'>

                                <div className='card-header border-0 pt-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bolder fs-3 mb-1'>Ordini Cliente</span>
                                    </h3>

                                    <div className="card-toolbar">

                                        {!isViewMode(location) && <>
                                            <a className="btn btn-success  m-1"
                                                onClick={(e) => addCustomerOrderRow()}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <i className="fas fa-solid fa-plus"></i>
                                                </span>Aggiungi
                                            </a>

                                            <a className="btn btn-danger m-1"
                                                onClick={(e) => removeCustomerOrderRow()}
                                            >
                                                <span className="svg-icon svg-icon-1">
                                                    <i className="fas fa-solid fa-trash"></i>
                                                </span>Rimuovi
                                            </a>
                                        </>}
                                    </div>
                                </div>

                                <div className='card-body py-3'>

                                    <div className="row">
                                        <DataTable
                                            columns={customerOrdersColumns}
                                            data={newWorkCycleViewModel?.customerOrderRows}
                                            highlightOnHover

                                            selectableRows
                                            selectableRowDisabled={(row) => { return isViewMode(location); }}
                                            selectableRowsHighlight
                                            selectableRowsSingle
                                            clearSelectedRows={clearSelectedRows}
                                            onSelectedRowsChange={onSelectedRowsChange}
                                        />
                                    </div>
                                </div>

                            </div>
                        
                        </>}

                        


                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                        Note
                                    </span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>

                                <div className='row'>

                                    <textarea
                                            className={clsx('form-control', newWorkCycleViewModel?.note?.isError ? 'bg-light-danger border-danger' : '')} 
                                            placeholder="" 
                                            value={newWorkCycleViewModel?.note?.value} 
                                            title={newWorkCycleViewModel?.note?.ErrorMessage} 
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue("note", e.target.value, e)}
                                            rows="5"
                                            {...(isViewMode()) && {disabled: true}}
                                        />

                                </div>
                            </div>
                        </div>

                        {isViewMode() && <>

                            <div className="d-none d-md-block" >

                                <div className='card mb-5 mb-xl-8 mt-5'>

                                    <div className='card-header border-0 pt-5'>

                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>
                                                Avanzamento Produzione [<i className="fas fa-stopwatch"></i> {newWorkCycleViewModel?.machineWorkCyclesDuration}]
                                            </span>
                                        </h3>

                                        <div className="card-toolbar">

                                        </div>
                                    </div>

                                    <div className='card-body py-3'>

                                        <div className='row mb-5'>

                                            <DataTable
                                                columns={columns}
                                                data={newWorkCycleViewModel?.machineWorkCycles}
                                                highlightOnHover
                                                striped
                                                dense
                                                conditionalRowStyles={conditionalRowStyles}
                                            />
                                        </div>
                                    </div>
                                </div>
                            
                            </div>

                            <div className="d-block d-md-none" >
                                <div className='card mb-5 mb-xl-8 mt-5'>

                                    <div className='card-header border-0 pt-5'>

                                        <h3 className='card-title align-items-start flex-column'>
                                            <span className='card-label fw-bolder fs-3 mb-1'>
                                                Avanzamento Produzione<br/>[<i className="fas fa-stopwatch"></i> {newWorkCycleViewModel?.machineWorkCyclesDuration}]
                                            </span>
                                        </h3>

                                        <div className="card-toolbar">

                                        </div>
                                    </div>

                                    <div className='card-body py-0 px-6 m-0 mb-5'>

                                                      {newWorkCycleViewModel?.machineWorkCycles?.map((wc, i) =>
                                                        <>
                                                              {wc.unitName != "WAITING" && <>

                                                                  <div className={clsx("row border border-1 py-0 pt-0 ps-0 pe-0 m-0", i % 2 == 0 ? "bg-light" : "")}>

                                                                      <div className="col-6 text-start ps-0">

                                                                          {wc.startDate != null && wc.endDate == null && <>
                                                                              <span className={clsx(`badge bg-warning`)}>&nbsp;&nbsp;&nbsp;&nbsp;In Corso&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                          </>}

                                                                          {wc.endDate != null && <>
                                                                              <span className={clsx(`badge bg-success`)}>Completato</span>
                                                                          </>}

                                                                          {wc.startDate == null && wc.endDate == null && <>
                                                                              <span className={clsx(`badge bg-secondary text-dark`)}>Non Definito</span>
                                                                          </>}
                                                                      </div>

                                                                      <div className="col-6 text-end pe-0">

                                                                          <Link to={`/units/${wc?.unitId}/jobs/${wc?.machineId}/viewJob/${wc?.id}`} >
                                                                              <i className="fas fa-eye pt-1 pe-1"></i>
                                                                          </Link>                                                                        

                                                                      </div>

                                                                      <div className="col-6 py-1">
                                                                          <div className="fs-9 fst-italic">Reparto</div>
                                                                          <div className="fs-6"> {wc.unitName}</div>
                                                                      </div>

                                                                      <div className="col-6 py-1">
                                                                          <div className="fs-9 fst-italic">Macchina</div>
                                                                          <div className="fs-6"> {wc.machineName}</div>
                                                                      </div>

                                                                      <div className="col-6 py-1">
                                                                          <div className="fs-9 fst-italic">Quantità</div>
                                                                          <div className="fs-6">
                                                                             {isModuleQualityControlActive ? <>

                                                                                {wc.quantityWarning ? <>

                                                                                    {wc?.leathersNumber}
                                                                                    <OverlayTrigger
                                                                                        placement='top'
                                                                                        overlay={<Tooltip>Attenzione! Quantità registrata differente da quella desiderata</Tooltip>}>
                                                                                        <i className="text-danger fas fa-exclamation-triangle ms-2 fs-5"></i>
                                                                                    </OverlayTrigger>
                                                                                </> :
                                                                                    <>
                                                                                        {wc?.leathersNumber}
                                                                                    </>}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {wc?.leathersNumber}
                                                                                </>
                                                                                }
                                                                             </div>
                                                                      </div>

                                                                      <div className="col-6 py-1">
                                                                          <div className="fs-9 fst-italic">Durata</div>
                                                                          <div className="fs-6"> {wc.durationStr}</div>
                                                                      </div>

                                                                      <div className="col-6 py-1">
                                                                          <div className="fs-9 fst-italic">Inizio</div>
                                                                          <div className="fs-6"> {wc.startDateShortStr}</div>
                                                                      </div>

                                                                      <div className="col-6 py-1">
                                                                          <div className="fs-9 fst-italic">Fine</div>
                                                                          <div className="fs-6"> {wc.endDateShortStr}</div>
                                                                      </div>

                                                                      {wc.hasNotes && <>

                                                                          <div className="col-12 py-1">
                                                                              <div className="fs-9 fst-italic">Note</div>
                                                                              <div class="alert alert-primary p-1 mb-0" role="alert">
                                                                                  {wc.notes}
                                                                              </div>
                                                                          </div>

                                                                      </>}

                                                                  </div>

                                                              </>}

                                                              {wc.unitName == "WAITING" && <>
                                                                  <div className={clsx("row border-bottom py-3 m-0 bg-secondary")}>
                                                                      <div className="col-12 py-1">
                                                                          Attesa: {wc.durationStr}<br />
                                                                      </div>
                                                                  </div>
                                                              </>}
                                                        </>)
                                                      }
                                        </div>
                                    </div>
                                </div>
                        </>
                        }

                        {isViewMode() && isModuleActive(MODULE_IDS.INVENTORY) && <>

                            <div className='card mb-5 mb-xl-8 mt-5'>

                                <div className='card-header border-0 pt-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bolder fs-3 mb-1'>
                                            Movimenti Magazzino
                                        </span>
                                    </h3>
                                </div>

                                <div className='card-body py-3'>
                                    <div className='row mb-5'>
                                        <DataTable
                                            columns={inventoryMovementsColumns}
                                            data={newWorkCycleViewModel?.inventoryMovements}
                                            highlightOnHover
                                            striped
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                        }


                        <div className="text-center">

                            {isViewMode() && <>
                                <div className="row justify-content-md-center">
                                    <div className="col col-12 col-md-4 my-5">
                                        <a className="btn btn-lg btn-primary w-100" onClick={(e) => navigate(`/productionOrders`)} >
                                            Archivio
                                        </a>
                                    </div>

                                    <div className="col col-12 col-md-4 my-5">
                                        <Link to={`/productionOrders/edit/${orderId}`} className='btn btn-lg btn-primary w-100'>
                                            Modifica
                                        </Link>                                    
                                    </div>

                                    <div className="col col-12 col-md-4 my-5">
                                        <div
                                            className='btn btn-lg btn-primary w-100'
                                            onClick={(e) => downloadPdf()}
                                            data-kt-indicator={isPdfInProgress == true ? 'on' : 'off'}>

                                            <span className="svg-icon svg-icon-1">
                                                <i className="fas fa-file-pdf"></i>
                                            </span>

                                            <span className="indicator-label" >Scarica PDF</span>
                                            <span className="indicator-progress" > Scarica PDF
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </div>                              
                                    </div>  

                                        <div className="col col-12 col-md-4 my-5">
                                            <div
                                                className='btn btn-lg btn-danger w-100'
                                                onClick={(e) => deleteEntity()}
                                                data-kt-indicator={deleteInProgress == true ? 'on' : 'off'}>

                                                <span className="indicator-label" >Elimina</span>
                                                <span className="indicator-progress" > Elimina
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </div>
                                        </div>
                                </div>

                                
                            </>}

                            {!isViewMode() && <>
                                <div className="row justify-content-md-center">

                                    <div className="col col-12 col-md-4 my-5">
                                        <a className="btn btn-lg btn-primary w-100" onClick={(e) => navigate(-1)} >
                                            Annulla
                                        </a>
                                    </div>

                                    <div className="col col-12 col-md-4 my-5">
                                        <button
                                            type="submit"
                                            id="kt_sign_in_submit"
                                            className="btn btn-lg btn-primary w-100"
                                            data-kt-indicator={showIndicatorProgress}>
                                            <span className="indicator-label">Salva</span>
                                            <span className="indicator-progress" >Caricamento...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </div>

                                </div>
                            </>}
                        </div>

                    </form>
                }
            </div>

            <Modal show={modalShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Esito Salvataggio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {saveResult &&
                            <div className="row d-flex justify-content-center">

                                <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                                    <span className="svg-icon svg-icon-2hx svg-icon-success mb-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                        </svg>
                                    </span>

                                    <div className="text-center">
                                        {/* <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div> */}
                                        <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                                    </div>
                                </div>

                            </div>
                        }

                        {!saveResult &&
                            <div className="row d-flex justify-content-center">

                                <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                                    <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                            <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                        </svg>
                                    </span>

                                    <div className="text-center">
                                        <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                        <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                        <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>


            {/* START - Confirmation Modal */}
            <Modal show={confirmationModalShow} onHide={handleConfirmationClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Conferma Operazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Sei sicuro di voler continuare?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleConfirmationClose}>Annulla</Button>
                    <Button variant="primary" onClick={confirmCreate}>Conferma</Button>
                </Modal.Footer>
            </Modal>

            {/* END - Confirmation Modal */}

{/* START - Delete Confirmation Modal */}
<Modal show={deleteConfirmationModalShow} onHide={handleDeleteConfirmationClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Conferma Operazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Sei sicuro di voler continuare?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteConfirmationClose}>Annulla</Button>
                    <Button variant="primary" onClick={confirmDelete}>Conferma</Button>
                </Modal.Footer>
            </Modal>

            {/* END - Delete Confirmation Modal */}                     
        </>

    )
}

export default ProdOrderCreate