import logo from './logo.svg';
import * as React from "react";
import './App.css';
import RequireAuth from './components/system/RequireAuth';
import PersistLogin from './components/system/PersistLogin';
import { Routes, Route, Navigate  } from "react-router-dom";
import Login from './components/utenti/Login';
import LayoutNavBar from './components/layout/LayoutNavBar';
import Home from './components/macchine/Home';
import Missing from './components/system/Missing';
import Unauthorized from './components/system/Unauthorized';

import { useModuleActive } from './hooks/useModuleActive';
import { MODULE_IDS } from './models/systems/ModuleIds';

import UserList from './components/utenti/UserList';
import UserView from './components/utenti/UserView';
import UserEdit from './components/utenti/UserEdit';
import UserCreate from './components/utenti/UserCreate';
import ROLES from './components/system/Roles';
import SystemLogList from './components/systemLog/SystemLogList';
import SystemLogView from './components/systemLog/SystemLogView';

import MachineList from './components/macchine/MachineList';

import MachineNavigation from './components/produzione/generic/MachineNavigation';
import MachineProdListV2 from './components/produzione/generic/MachineProdListV2';
import MachineProdCreateV2 from './components/produzione/generic/MachineProdCreateV2';
import MachineProdViewV2 from './components/produzione/generic/MachineProdViewV2';

import MachineProdViewV3 from './components/produzione/generic/MachineProdViewV3';
import MachineProdCreateV3 from './components/produzione/generic/MachineProdCreateV3';
import MachineProdEditV3 from './components/produzione/generic/MachineProdEditV3';

import ProdList from './components/produzione/generic-global/ProdList';

import PackingListNavigation from './components/produzione/packing-list/PackingListNavigation';
import PackingList from './components/produzione/packing-list/PackingList';
import MachineGerT2List from './components/produzione/packing-list/MachineGerT2List';
import PackingListCreate from './components/produzione/packing-list/PackingListCreate';
import PackingListView from './components/produzione/packing-list/PackingListView';

import CustomerList from './components/profile/customers/CustomerList';
import CustomerCreate from './components/profile/customers/CustomerCreate';
import CustomerView from './components/profile/customers/CustomerView';

import UnitsDashboard from './components/produzione/unit/UnitsDashboard'
import UnitProdList from './components/produzione/unit/UnitProdList'
import UnitNavigation from './components/produzione/unit/UnitNavigation'
import UnitMachines from './components/produzione/unit/UnitMachines'

import ProdOrderList from './components/produzione/orders/ProdOrderList'
import ProdOrderCreate from './components/produzione/orders/ProdOrderCreate'
import ProdOrderNavigation from './components/produzione/orders/ProdOrderNavigation'

import InventoryMovements from './components/inventory/movements/InventoryMovements'
import InventoryMovementsNavigation from './components/inventory/movements/InventoryMovementsNavigation'
import InventoryMovementsLoadHandler from './components/inventory/movements/InventoryMovementsLoadHandler'
import InventoryMovementsUnloadHandler from './components/inventory/movements/InventoryMovementsUnloadHandler'
import InventoryStocks from './components/inventory/movements/InventoryStocks'

import InventoryArticleHandler from './components/profile/articles/InventoryArticleHandler'
import InventoryArticleList from './components/profile/articles/InventoryArticleList'
import InventoryBatches from './components/inventory/batches/InventoryBatches'
import InventoryBatchesHandler from './components/inventory/batches/InventoryBatchesHandler'
import AccountList from './components/profile/suppliers/AccountList';
import AccountHandler from './components/profile/suppliers/AccountHandler';
import MachineDashboardHandler from './components/macchine/MachineDashboardHandler';

import ChemicalsProducts from './components/chemicals/products/ChemicalsProducts';

import NavHome from './components/navigation/home/NavHome';
import NavInventory from './components/navigation/inventory/NavInventory';

import InventoryBoardItemList from './components/inventory/boardItems/InventoryBoardItemList';
import InventoryBoardItemHandler from './components/inventory/boardItems/InventoryBoardItemHandler';


export default function App() {

  const isModuleActive = useModuleActive();

  return (
      <Routes>

        <Route path="/" >
        {/* public routes */}
          <Route path="login" element={<Login/>} />
          
          <Route path="unauthorized" element={<Unauthorized/>} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>  

          <Route element={<LayoutNavBar />}>

            <Route element = {<RequireAuth allowedRoles={[ROLES.Operatore]}/>}>
              
            <Route path="/home" element={<NavHome/>} />     
              <Route exact path="/" element={<Navigate to="/home" replace />}/>
            </Route>

            <Route path="/interconnections" element={<Home/>} />     
            <Route path="/inventory" element={<NavInventory/>} />     


            <Route element = {<RequireAuth allowedRoles={[ROLES.Operatore, ROLES.Supervisore, ROLES.Amministratore]}/>}>

              <Route element={<MachineNavigation/>} >
                <Route path="macchina/:idMacchina/:codiceMacchina/dashboard" element={<MachineDashboardHandler/>} />
                <Route path="produzione/:idMacchina/:codiceMacchina/lavorazioni" element={<MachineProdListV2/>} />
                <Route path="produzione/:idMacchina/:codiceMacchina/nuovaLavorazione" element={<MachineProdCreateV2/>} />
                <Route path="produzione/:idMacchina/:codiceMacchina/lavorazione/:idLavorazione" element={<MachineProdViewV2/>} />
                <Route path="produzione/:idMacchina/:codiceMacchina/modificaLavorazione/:idLavorazione" element={<MachineProdCreateV2/>} />

                {/* <Route path="produzione/:tipoMacchina/:idMacchina/:codiceMacchina/nuovaLavorazione" element={<MachineProdCreateV2/>} />
                <Route path="produzione/:tipoMacchina/:idMacchina/:codiceMacchina/lavorazione/:idLavorazione" element={<MachineProdViewV2/>} />
                <Route path="produzione/:tipoMacchina/:idMacchina/:codiceMacchina/modificaLavorazione/:idLavorazione" element={<MachineProdCreateV2/>} />                 */}
              </Route>

              <Route path="produzione" element={<ProdList/>} />

              <Route path="units" element={<UnitsDashboard/>} />
                
              <Route element={<UnitNavigation/>} >
                <Route path="units/:unitId/jobs/" element={<UnitProdList/>} />
                <Route path="units/:unitId/machines/" element={<UnitMachines/>} />
                
                <Route path="units/:unitId/jobs/:idMacchina/createJob" element={<MachineProdCreateV3/>} />
                <Route path="units/:unitId/jobs/:idMacchina/viewJob/:idLavorazione" element={<MachineProdViewV3/>} />
                <Route path="units/:unitId/jobs/:idMacchina/editJob/:idLavorazione" element={<MachineProdEditV3/>} />
              </Route>

              <Route element={<InventoryMovementsNavigation/>} >
                <Route element={<RequireAuth allowedRoles={[ROLES.Operatore, ROLES.Supervisore, ROLES.Amministratore]} />}>
                  <Route path="inventory/:inventoryId/movements" element={<InventoryMovements />} />
                  <Route path="inventory/:inventoryId/movements/:handlerMode/load/:entityId" element={<InventoryMovementsLoadHandler />} />
                  <Route path="inventory/:inventoryId/movements/:handlerMode/unload/:entityId" element={<InventoryMovementsUnloadHandler />} />
                  <Route path="inventory/:inventoryId/stock" element={<InventoryStocks />} />
                </Route>
              </Route>

              {/* {isModuleActive(MODULE_IDS.INVENTORY_BOARD) && <> */}

                <Route element={<RequireAuth allowedRoles={[ROLES.Supervisore, ROLES.Amministratore]} />}>
                  <Route path="inventory/boardItems" element={<InventoryBoardItemList />} />
                <Route path="inventory/boardItem/:handlerMode/:entityId" element={<InventoryBoardItemHandler />} />
                </Route>

              {/* </>
              } */}

              <Route element={<RequireAuth allowedRoles={[ROLES.Supervisore, ROLES.Amministratore]} />}>

                {/* <Route path="inventory/account/:accountType/list" element={<AccountList />} />
                <Route path="inventory/account/:accountType/:handlerMode/:accountId" element={<AccountHandler />} />

                <Route path="inventory/article/:entityType/list" element={<InventoryArticleList />} />
                <Route path="inventory/article/:entityType/:handlerMode/:entityId" element={<InventoryArticleHandler />} /> */}

                <Route path="profiles/account/:accountType/list" element={<AccountList />} />
                <Route path="profiles/account/:accountType/:handlerMode/:accountId" element={<AccountHandler />} />

                <Route path="profiles/article/:entityType/list" element={<InventoryArticleList />} />
                <Route path="profiles/article/:entityType/:handlerMode/:entityId" element={<InventoryArticleHandler />} />
                
                <Route path="profiles/customer/view/:customerId" element={<CustomerView/>} />
                <Route path="profiles/customer/list" element={<CustomerList/>} />
                <Route path="profiles/customer/create" element={<CustomerCreate/>} />
                <Route path="profiles/customer/edit/:customerId" element={<CustomerCreate/>} />                

                <Route path="inventory/leather/batches" element={<InventoryBatches />} />
                <Route path="inventory/leather/batches/:handlerMode/:entityId" element={<InventoryBatchesHandler />} />                
            </Route>

              {isModuleActive(MODULE_IDS.CHEMICALS) && <>

                <Route element={<RequireAuth allowedRoles={[ROLES.Supervisore, ROLES.Amministratore]} />}>
                  <Route path="chemicals/product/list" element={<ChemicalsProducts />} />
                  {/* <Route path="chemicals/product/:handlerMode/:entityId" element={<AccountHandler />} /> */}
                </Route>

              </>
              }


            </Route>

            {/* {isModuleActive(MODULE_IDS.PRODUCTION_ORDER) && <>
              </>
              } */}

              <Route element={<RequireAuth allowedRoles={[ROLES.Supervisore, ROLES.Amministratore]} />}>
                <Route element={<ProdOrderNavigation />} >
                  <Route path="productionOrders" element={<ProdOrderList />} />
                  <Route path="productionOrders/:handlerMode/:orderId" element={<ProdOrderCreate />} />
                </Route>
              </Route>

            <Route element = {<RequireAuth allowedRoles={[ROLES.Amministratore]}/>}>

              <Route element={<PackingListNavigation/>} >
                <Route path="packingList/list" element={<PackingList/>} />
                <Route path="packingList/create" element={<PackingListCreate/>} />
                <Route path="packingList/edit/:packingListId" element={<PackingListCreate/>} />
                <Route path="packingList/view/:packingListId" element={<PackingListView/>} />
                <Route path="packingList/t2s" element={<MachineGerT2List/>} />
              </Route>

              <Route path="settings/users" element={<UserList/>} />
              <Route path="settings/user/:idUser" element={<UserView/>} />
              <Route path="settings/user/newUser" element={<UserCreate/>} />
              <Route path="settings/editUser/:idUser" element={<UserEdit/>} />
              <Route path="settings/systemLogs" element={<SystemLogList/>} />
              <Route path="settings/systemLog/:idLog" element={<SystemLogView/>} />

              <Route path="settings/machines" element={<MachineList/>} />
            </Route>

          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing/>} />

        </Route>
      </Routes>
  );
}