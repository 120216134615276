import clsx from 'clsx'
import { useNavigate, Link} from "react-router-dom";
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

export function ProductionOrderStatus(props) 
{
        var bgClass = '';
        var statusName = '';

        if (props.row.status == 0) // ND
        {
          bgClass = 'bg-secondary  text-dark';
          statusName = 'Non Definito';
        }
        else if (props.row.status == 1) // TODO
        {
          bgClass = 'bg-primary';
          statusName = 'Da Fare';
        }
        else if (props.row.status == 2) // INPROGRESS
        {
          bgClass = 'bg-warning text-dark';
          statusName = 'In Produzione';
        }
        else if (props.row.status == 3) // DONE
        {
          bgClass = 'bg-success';
          statusName = 'Completato';
        }
        else if (props.row.status == 4) // IN STOCK
        {
          bgClass = 'bg-danger';
          statusName = 'In Stock (' + props.row.rimanenza +')' ;
        }        

        return (<span className={clsx(`badge ${bgClass}`)}>{statusName}</span>);
}

export function ProductionOrderMobilePagination(props) 
{
    var currentPage = props.currentPage;
    var perPage = props.perPage;
    var totalRows = props.totalRows;

    return (
        <>
        <div className="col-12 mt-10">
            <ul className="pagination pagination-lg">
              <li className={clsx('page-item previous', {'disabled' : currentPage == 1})}>
                <div className="page-link" onClick={(e) => props.onGoToPrevPage()}>
                  <i className="previous"></i>
                </div>
              </li>
              
              <li className="page-item">
                <a className="page-link">
                {1 + ((currentPage -1) * perPage)} - {((((currentPage -1) * perPage)) + perPage ) > totalRows ? totalRows : ((((currentPage -1) * perPage)) + perPage ) } di {totalRows}
                </a>
              </li>

              <li className={clsx('page-item next', { 'disabled': currentPage == Math.ceil(totalRows / perPage) })}>
                <div className="page-link" onClick={(e) => props.onGoToNextPage()}>
                  <i className="next"></i>
                </div>
              </li>
            </ul>
          </div>
          </>
    )
}

export function ProductionOrderCard(props) 
{
    const navigate = useNavigate();
    var po = props.productionOrder;
    return (
        <>
            <div className='card mb-5 mt-5' onClick = {() => navigate(`/productionOrders/view/${po.id}`)}>
                <div className='card-header border-0 pt-5'>

                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3'>{po?.orderNumber}</span>
                    </h3>
                    <div className="card-toolbar">
                        <ProductionOrderStatus row={po} />
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className="row">

                        <div className="col-6 mb-3">
                            <div className="fs-9 fw-bold">Cliente</div>
                            <div className="fs-2"> {po?.customer}</div>
                        </div>

                        <div className="col-6 mb-3">
                            <div className="fs-9 fw-bold">Numero Pelli</div>
                            <div className="fs-2"> 
                              {props.isModuleQualityControlActive ? //Se il modulo controllo quantità è attivo allora la visualizzazione della quantità viene adeguata di conseguenza
                                  <>
                                    {(po?.leathersNumber != null && po?.lastLeathersNumber != null && po?.leathersNumber != po?.lastLeathersNumber) ?
                                      <>
                                      {po?.lastLeathersNumber + '/' + '' + po?.leathersNumber}
                                        <OverlayTrigger
                                          placement='top'
                                          overlay={<Tooltip>Attenzione! Ultima quantità registrata differente da quella desiderata</Tooltip>}>
                                          <i class="text-danger fas fa-exclamation-triangle ms-2 fs-3"></i>
                                        </OverlayTrigger>
                                      </>
                                      : <>
                                        {po?.leathersNumber}
                                      </>
                                    }
                                  </>
                                  :
                                  <>
                                    {po?.leathersNumber}
                                  </>
                                }

                              </div>
                        </div>

                        <div className="col-6 mb-3">
                            <div className="fs-9 fw-bold">Articolo</div>
                            <div className="fs-2"> {po?.article}</div>
                        </div>

                        <div className="col-6 mb-3">
                            <div className="fs-9 fw-bold">Colore</div>
                            <div className="fs-2"> {po?.color}</div>
                        </div>

                        <div className="col-6 mb-3">
                            <div className="fs-9 fw-bold">Consegna</div>
                            <div className="fs-2"> {po?.dataConsegnaStr}</div>
                        </div>

                        <div className="col-6 mb-3">
                            <div className="fs-9 fw-bold">Ultima lavorazione</div>
                            <div className="fs-2"> {po?.lastJob?.machineName}</div>
                        </div>

              {po?.hasNotes != "" && <>

                <div className="col-12 py-1">
                  <div className="fs-9 fw-bold">Note</div>
                  <div class="alert alert-primary p-1 mb-0" role="alert">
                    {po?.note}
                  </div>
                </div>

              </>}
                    </div>

                </div>

            </div>
        </>
    )
}


export function normalizeEntity(entityModel) 
{
    if(entityModel.dataOrdine.value != "")
    {
      entityModel.dataOrdine.value = new Date(entityModel.dataOrdine.value);
    }

    if(entityModel.dataConsegna.value != "")
    {
      entityModel.dataConsegna.value = new Date(entityModel.dataConsegna.value);
    }

    //Trasformazione delle liste per renderle compatibili con le relative select
    entityModel.articles = entityModel.articles.reduce((res, item) => {

      let label = "";

      if (item.code != "" && (! (typeof item.code === 'undefined'))) {
        label += "[" + item.code + "] - ";
      }
      
      if (item.name != "" && (! (typeof item.name === 'undefined'))) {
        label += item.name + " ";
      }

      if (item.color != "" && (! (typeof item.color === 'undefined'))) {
        label += item.color;
      }

        res.push({ 
          value: item.id, 
          label: label, 
          articleCode: item.code,
          articleName: item.name,
          colorName: item.color,
        });
        return res;
    }, []);    

    if(entityModel.articleId.value != "")
    {
        var selectEntry = {};
        entityModel.articles.forEach(entry=>{
    
            if(entry.value == entityModel.articleId.value)
            {
                selectEntry = entry;
            }
        })
    
        entityModel.articleId.value = selectEntry;
    }

    return entityModel;
}


export function getPageMode(location)
{
  var output = '';

  if (location.pathname.indexOf("productionOrders/view") > -1) {
    output = 'view';
  }
  else if (location.pathname.indexOf("productionOrders/create") > -1) {
    output = 'create';
  }
  else if (location.pathname.indexOf("productionOrders/edit") > -1) {
    output = 'edit';
  }

  return output;
}

export function getPayload(entityModel)
{
  let output = {... entityModel};
  
  //Lettura dell'id dell'articolo selezionato
  output.articleId.value = ''+entityModel.articleId.value.value;

  return output;
}