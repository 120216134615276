import useAuth from './useAuth';

export const useModuleActive = () => {
  const authObj = useAuth();

  // Funzione per controllare se un modulo è attivo
  const isModuleActive = (moduleId) => {

    var output = false;

    try 
    {
      if(authObj?.auth?.frontEndConfig?.[moduleId] == "true")
      {
          output = true; 
      }

      return output;
    } 
    catch (error) 
    {
      console.error('Errore durante il controllo del modulo:', error);
      return output;
    }
  };

  return isModuleActive;
};
