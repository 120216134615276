import { Spinner, Modal, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import useAxiosPrivate from './../../../hooks/useAxiosPrivate';
import ChemicalsProductProcessedComponent from './ChemicalsProductProcessedComponent';

export function AddProductComponent(props) {

    const axiosPrivate = useAxiosPrivate();
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();

    const [isRequestInProgress, setIsRequestInProgress] = useState(false);

    const [requestResult, setRequestResult] = useState({
        completed: false
    });

    const [isResultModalVisible, setIsResultModalVisible] = useState(false);

    const saveFile = (e) => {

        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    const processWithAi = async (e) => {

        if(isRequestInProgress)
        {
            return;
        }

        if (typeof file === 'undefined') {
            return;
        }

        setIsRequestInProgress(true);

        let isMounted = true;
        const controller = new AbortController();

        const sendRequest = async () => {

            try {
                const formData = new FormData();
                formData.append("formFile", file);
                formData.append("formName", fileName);

                const res = await axiosPrivate.post(`/chemicalsProduct/processWithAi`,
                    formData,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                        signal: controller.signal
                    });

                if (isMounted) 
                {
                    console.log(res);

                    setRequestResult(res.data);
                    setIsResultModalVisible(true);

                    setIsRequestInProgress(false);

                    props.onHide();
                }
            }
            catch (err) 
            {
                console.error(err);

                setRequestResult({isError:true, message: err?.response?.statusText});
                setIsResultModalVisible(true);

                setIsRequestInProgress(false);
                // setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
            }
            
        }

        sendRequest();
    };

    const resultModalOnClose = () => 
    {
        if (!isRequestInProgress) 
        {
            setIsResultModalVisible(false)
        }
    }


    return <>

        {/* START - Select File Modal */}
        <Modal show={props.show} onHide={()=>{ if(!isRequestInProgress){props.onHide()}  }}>

            <Modal.Header closeButton>
                <Modal.Title>Aggiungi Nuovo Prodotto</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">

                    <div className="col-12 mt-5">
                        <div className="alert alert-info">
                            Carica il file PDF del prodotto che vuoi aggiungere
                        </div>
                    </div>

                    <div className="col-12 mt-5">
                        <div>
                            {/* <label for="formFile" class="form-label">Default file input example</label> */}
                            <input
                            disabled={isRequestInProgress}
                             className="form-control" type="file" id="formFile" accept=".pdf" onChange={saveFile} />
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide} disabled={isRequestInProgress}>Annulla</Button>

                <div
                    className="btn btn-info m-5"
                    onClick={(e)=> processWithAi()}
                    data-kt-indicator={isRequestInProgress ? "on" : "off"}>
                    <span className="indicator-label">Elabora con IA</span>
                    <span className="indicator-progress" >Sto elaborando
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </div>

            </Modal.Footer>
        </Modal>
        {/* END - Select File Modal */} 

        {/* START - Result Modal */}
        <Modal 
            size="lg"
            show={isResultModalVisible}>

            <Modal.Header>
                <Modal.Title>Esito Elaborazione</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">

                    <div className="col-12">
                        <ChemicalsProductProcessedComponent data={requestResult.jsonObject} />
                    </div>

                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={resultModalOnClose}>Chiudi</Button>
                <Button variant="primary" onClick={props.onConfirm} disabled>Conferma</Button>
            </Modal.Footer>
        </Modal>
        {/* END - Select Result Modal */}         

    </>;
}