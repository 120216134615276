import { useNavigate, Link, useLocation, Outlet, useParams } from "react-router-dom";
import { useState, useEffect, useMemo } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { usePageData } from '../../../_metronic/layout/core';
import useAuth from "../../../hooks/useAuth";
import clsx from 'clsx'
import DatePicker, { registerLocale } from 'react-datepicker';

import { isCreateMode, isEditMode, isViewMode, validateProperty, validateWorkCycle, checkValidationErrors, normalizeEntity } from "./InventoryBoardItemHelper";

import {ConfirmationModal, ResultModal} from "./Modals";
import {PageErrorMessage, FormInputString} from "./Components";

const InventoryBoardItemHandler = () => {    
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [isError, setIsError] = useState();
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
    const [modalShow, setModalShow] = useState();
    const [modalMessage, setModalMessage] = useState();
    const [saveInProgress, setSaveInProgress] = useState();
    const [deleteInProgress, setDeleteInProgress] = useState();
    const [saveResult, setSaveResult] = useState();
    const [newWorkCycleViewModel, setNewWorkCycleViewModel] = useState();
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [deleteConfirmationModalShow, setDeleteConfirmationModalShow] = useState(false);
    const [operationInProgress, setOperationInProgress] = useState("");
    
    var forseUpdate = new Date();
    
    let { entityId } = useParams();
    let { handlerMode } = useParams();

    let navPrefix = "/inventory";
    let entityName = "Giacenza";
    let entitiesName = "Giacenze";

    const { setPageTitle, pageMachines } = usePageData();
    const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();


    const handleClose = () => 
    {
        if (saveResult == true) 
        {
            if(operationInProgress == "delete")
            {
                navigate(`${navPrefix}/boardItems`);
            }
            else
            {
                navigate(`${navPrefix}/boardItem/view/${newWorkCycleViewModel.id}`);
            }
        }

        setModalShow(false);
    }

    const handleConfirmationClose = () => {
        setConfirmationModalShow(false);
    }

    const handleDeleteConfirmationClose = () => {
        setDeleteConfirmationModalShow(false);
    }

    const confirmCreate = async () => 
    {
        setConfirmationModalShow(false);

        //Se c'è un salvataggio in corso non possono essere inoltrare ulteriori richieste
        if (saveInProgress) {
            return;
        }

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors(newWorkCycleViewModel) == true) {
            return;
        }

        setLoadingSave(true);
        setSaveInProgress(true);

        //Impostazione dell'id macchina per cui creare il ciclo di lavoro

        var endpoint = "";

        if(isEditMode(location))
        {
            endpoint = `/inventoryBoard/editEntity`;
        }
        else
        {
            endpoint = `/inventoryBoard/createEntity`;
        }

        let isMounted = true;
        const controller = new AbortController();

        const sendRequest = async () =>
        {
            try 
            {

                const response = await axiosPrivate.post(endpoint,
                    JSON.stringify(newWorkCycleViewModel),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                        signal:controller.signal
                    }
                );
    
                if (isMounted) {
                    setNewWorkCycleViewModel({ ...newWorkCycleViewModel, id: response.data });
    
                    setModalMessage("Operazione avvenuta con successo");
                    setLoadingSave(false);
                    setSaveInProgress(false);
                    setSaveResult(true);
                    setModalShow(true);
                }
            }
            catch (err) 
            {
                console.error(err);
                setSaveResult(false);
                setModalMessage(`${err?.response?.data?.message}`);
                setLoadingSave(false);
                setSaveInProgress(false);
                setModalShow(true);
            }
        }

        sendRequest();

        return () => {
            isMounted = false;
            controller.abort();
          }
    }

    const setPropertyValue = (propertyCode, value, event) => {

        if (propertyCode == "exitDate" || event.target.validity.valid) {
            var wc = { ...newWorkCycleViewModel };

            wc[propertyCode].value = value;
            wc[propertyCode] = validateProperty(wc[propertyCode]);

            setNewWorkCycleViewModel(wc);
        }
    }

    const setLoadingSave = (isLoading) => {
        if (isLoading) {
            setShowIndicatorProgress('on');
        }
        else {
            setShowIndicatorProgress('off');
        }
    }

    const deleteEntity = () => 
    {
        if(deleteInProgress)
        {
            return;
        }

        setDeleteConfirmationModalShow(true);
    }

    const undo = () =>
    {
        navigate(-1);
    }

    const confirmDeleteEntity = async () => {

        setDeleteConfirmationModalShow(false);

        //Se c'è un salvataggio in corso non possono essere inoltraTe ulteriori richieste
        if(deleteInProgress)
        {
            return;
        }

        setLoadingSave(true);
        setDeleteInProgress(true);
        setOperationInProgress("delete");

        let isMounted = true;
        const controller = new AbortController();

        const sendRequest = async () =>
        {
                //Impostazione dell'id macchina per cui creare il ciclo di lavoro
                        
                try 
                {
                    const response = await axiosPrivate.get(`/inventoryBoard/deleteEntity/${entityId}`, 
                        {
                            headers: {'Content-Type':'application/json'},
                            withCredentials: true,
                        }
                    );

                    if (isMounted) 
                    {
                        setModalMessage("Operazione avvenuta con successo");
                        setLoadingSave(false);
                        setDeleteInProgress(false);
                        setSaveResult(true);
                        setModalShow(true);
                    }
                }
                catch(err) 
                {
                    console.error(err);
                    setSaveResult(false);
                    setModalMessage(`${err?.response?.data?.message}`);
                    setLoadingSave(false);
                    setDeleteInProgress(false);
                    setModalShow(true);
                    setOperationInProgress("");
                }    
        }

        sendRequest();

        return () => {
            isMounted = false;
            controller.abort();
          }
    }    

    //Setting Page BreadCrumb
    useEffect(() => {

        setPageTitle("Anagrafiche");
        
        var breadcrumb = `Nuova ${entityName}`;
        
        if(isEditMode(location))
        {
            breadcrumb = `Modifica ${entityName} N. ${entityId}`;
        }
        else if(isViewMode(location))
        {
            breadcrumb = `Dettaglio ${entityName} N. ${entityId}`;
        }

        setPageBreadcrumbs([entitiesName,breadcrumb]);
    }, [entityId, handlerMode])

    useEffect(() => {

        setIsLoading(true);

        let isMounted = true;
        const controller = new AbortController();

        const getEntityModel = async () => {

            try {

                const response = await axiosPrivate.get(`/inventoryBoard/getEntityModel/${entityId}`, 
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,                    
                    signal: controller.signal
                });

                if (isMounted) {

                    response.data.createdBy = auth.userId;

                    response.data = normalizeEntity(response.data);
                    
                    //response.data.Type = entityType.toUpperCase();

                    //Validazione iniziale
                    validateWorkCycle(response.data);

                    setNewWorkCycleViewModel(response.data);

                    setIsLoading(false);
                    setIsError(false);
                }
            }
            catch (err) {
                console.error(err);
                setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
                setIsLoading(false);
                setIsError(true);
            }
        }

        getEntityModel();

    }, [entityId, handlerMode])


    const handleSubmit = (e) => {

        e.preventDefault();

        //Se c'è un errore di validazione allora non viene inoltrata la richiesta al server
        if (checkValidationErrors(newWorkCycleViewModel) == true) {
            return;
        }

        setConfirmationModalShow(true);
    }

    return (
        <>
            <div id="kt_content_container" className="mx-5">

                {/* begin::Spinner */}
                {isLoading &&
                    <div className="row d-flex justify-content-center m-20">
                        <Spinner animation="border" variant="primary" />
                    </div>
                }
                {/* end::Spinner */}

                {/* begin::Error message */}
                {!isLoading && isError &&
                    <PageErrorMessage message={errorMessage}/>
                }
                {/* end::Error message */}

                {!isLoading && !isError &&

                    <form
                        className="form w-100"
                        noValidate="novalidate"
                        id="kt_sign_in_form"
                        onSubmit={handleSubmit}>

                        <div className='card mb-5 mb-xl-8 mt-5'>

                            <div className='card-header border-0 pt-5'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>
                                        {isEditMode(location) && <>Modifica {entityName} - Intestazione</>}
                                        {isViewMode(location) && <>Dettaglio {entityName} - Intestazione</>}
                                        {isCreateMode(location) && <>Nuovo {entityName} - Intestazione</>}
                                    </span>
                                </h3>
                            </div>

                            <div className='card-body py-3'>

                                <div className="row">

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Posizione</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.position.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.position.value}
                                            title={newWorkCycleViewModel?.position.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('position', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Num. Ordine</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.productionOrderNumber.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.productionOrderNumber.value}
                                            title={newWorkCycleViewModel?.productionOrderNumber.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('productionOrderNumber', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Scelta</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.selection.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.selection.value}
                                            title={newWorkCycleViewModel?.selection.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('selection', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Fornitore</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.supplier.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.supplier.value}
                                            title={newWorkCycleViewModel?.supplier.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('supplier', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Quantità</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.quantity.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*"
                                            value={newWorkCycleViewModel?.quantity.value}
                                            title={newWorkCycleViewModel?.quantity.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('quantity', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Data Uscita</label>

                                        <DatePicker
                                            placeholderText="Scegli Data"
                                            locale="it"
                                            className={clsx(
                                                'form-control form-control-solid',
                                                {
                                                  'bg-light-danger border-danger': newWorkCycleViewModel?.exitDate?.isError
                                                }
                                              )}

                                            autoComplete="off"

                                            name="data"
                                            dateFormat="dd/MM/yyyy"

                                            selected={newWorkCycleViewModel?.exitDate.value}
                                            onChange={(date) => setPropertyValue('exitDate', date, null)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />

                                    </div>                                    

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Terzista</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.subcontractor.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            value={newWorkCycleViewModel?.subcontractor.value}
                                            title={newWorkCycleViewModel?.subcontractor.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('subcontractor', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-3">
                                        <label className="form-label">Num. Pedane</label>
                                        <input type="text"
                                            className={clsx('form-control', newWorkCycleViewModel?.palletNumber.isError ? 'bg-light-danger border-danger' : '')}
                                            placeholder=""
                                            pattern="[0-9]*"
                                            value={newWorkCycleViewModel?.palletNumber.value}
                                            title={newWorkCycleViewModel?.palletNumber.errorMessage}
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue('palletNumber', e.target.value, e)}
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>

                                    <div className="mb-10 col-12 col-md-12">
                                        <label className="form-label">Note</label>
                                        <textarea
                                            className={clsx('form-control', newWorkCycleViewModel?.notes?.isError ? 'bg-light-danger border-danger' : '')} 
                                            placeholder="" 
                                            value={newWorkCycleViewModel?.notes?.value} 
                                            title={newWorkCycleViewModel?.notes?.ErrorMessage} 
                                            autoComplete="off"
                                            onChange={(e) => setPropertyValue("notes", e.target.value, e)}
                                            rows="5"
                                            {...(isViewMode(location)) && {disabled: true}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-center">

                            {isViewMode(location) && <>

                                <a className="btn btn-lg btn-primary w-25 m-5" onClick={(e) => navigate(`${navPrefix}/boardItems`)} >
                                    Elenco
                                </a>

                                <Link to={`${navPrefix}/boardItem/edit/${entityId}`} className='btn btn-lg btn-primary w-25 m-5'>
                                    Modifica
                                </Link>

                                <div className='btn btn-lg btn-danger w-25 m-5' onClick={(e) => deleteEntity()} data-kt-indicator={deleteInProgress ? "on": "off"}>
                                        <span className="indicator-label" >Elimina</span>
                                        <span className="indicator-progress" > Elimina
                                            <span className="spinner-border spinner-border-sm align-middle ms-2">
                                        </span>
                                    </span>
                                </div>  
                            </>
                            }

                            {!isViewMode(location) && <>

                                <a className="btn btn-lg btn-primary w-25 m-5" onClick={(e) => undo()} >
                                    Annulla
                                </a>

                                <button
                                    type="submit"
                                    id="kt_sign_in_submit"
                                    className="btn btn-lg btn-primary w-25 m-5"
                                    data-kt-indicator={showIndicatorProgress}>
                                    <span className="indicator-label">Salva</span>
                                    <span className="indicator-progress" >Caricamento...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </>
                            }
                        </div>

                    </form>
                }
            </div>

            <ConfirmationModal 
                show={deleteConfirmationModalShow} 
                onHide={handleDeleteConfirmationClose} 
                onConfirm={confirmDeleteEntity} />

            <ConfirmationModal 
                show={confirmationModalShow} 
                onHide={handleConfirmationClose} 
                onConfirm={confirmCreate} />

            <ResultModal 
                show={modalShow} 
                onHide={handleClose} 
                result={saveResult}
                message={modalMessage}/> 
        </>

    )
}

export default InventoryBoardItemHandler