import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { usePageData } from '../../../_metronic/layout/core';
import { useModuleActive } from '../../../hooks/useModuleActive';
import useAuth from '../../../hooks/useAuth';
import { MODULE_IDS } from '../../../models/systems/ModuleIds';
import { isAdmin, isSupervisor } from '../../utility/SystemFunctions';

import { Spinner } from 'react-bootstrap';
import clsx from 'clsx';

const NavInventory = () => {

    const isModuleActive = useModuleActive();
    const authObj = useAuth();

    const { setPageTitle } = usePageData();
    const { setPageBreadcrumbs } = usePageData();

    //Setting Page BreadCrumb
    useEffect(() => {
        setPageTitle("Magazzino");
        setPageBreadcrumbs([]);
    }, [])


    return (
        <>

            <div id="kt_content_container" className="mx-5 py-6">

                <section>
                    <div className="row gy-5 g-xl-8 mt-5">



                        {isModuleActive(MODULE_IDS.INVENTORY) && <>

                            {(isAdmin(authObj) || isSupervisor(authObj)) && <>

                                <div className='col-xl-4 col-md-6 col-sm-12 m-auto'>
                                    <Link to={`/inventory/0/movements`}>

                                        <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                            <div className='card-body border-0'>
                                                <div className="d-flex position-relative">
                                                    <div className="my-4 me-4 lh-sm">
                                                        <h3 className="mb-0">Magazzino Piclato</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='col-xl-4 col-md-6 col-sm-12 m-auto'>
                                    <Link to={`/inventory/2/movements`}>

                                        <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                            <div className='card-body border-0'>
                                                <div className="d-flex position-relative">
                                                    <div className="my-4 me-4 lh-sm">
                                                        <h3 className="mb-0">Magazzino WB</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='col-xl-4 col-md-6 col-sm-12 m-auto'>
                                    <Link to={`/inventory/1/movements`}>

                                        <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                            <div className='card-body border-0'>
                                                <div className="d-flex position-relative">
                                                    <div className="my-4 me-4 lh-sm">
                                                        <h3 className="mb-0">Magazzino Crust</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>


                                <div className='col-xl-4 col-md-6 col-sm-12 m-auto'>
                                    <Link to={`/inventory/3/movements`}>

                                        <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                            <div className='card-body border-0'>
                                                <div className="d-flex position-relative">
                                                    <div className="my-4 me-4 lh-sm">
                                                        <h3 className="mb-0">Magazzino Finito</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                {isAdmin() && <>
                                    <Link to={`/inventory/leather/batches`}>

                                        <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                            <div className='card-body border-0'>
                                                <div className="d-flex position-relative">
                                                    <div className="my-4 me-4 lh-sm">
                                                        <h3 className="mb-0">Magazzino Finito</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </>}


                            </>}

                        </>}

                        {isModuleActive(MODULE_IDS.INVENTORY_BOARD) && <>

                            <div className='col-xl-4 col-md-6 col-sm-12 m-auto'>
                                <Link to={`/inventory/boardItems`}>

                                    <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                        <div className='card-body border-0'>
                                            <div className="d-flex position-relative">
                                                <div className="my-4 me-4 lh-sm">
                                                    <h3 className="mb-0">Lavagna giacenze</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        </>}
                    </div>
                </section>
            </div>
        </>

    )
}

export default NavInventory