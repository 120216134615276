import { Spinner, Modal, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import useAxiosPrivate from './../../../../hooks/useAxiosPrivate';
import './../../../../styles/Global.css';

export function ProdOrderAiPreloadComponent(props) {

    const axiosPrivate = useAxiosPrivate();
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();

    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [isModalShow, setIsModalShow] = useState(false);
    const [isErrorModalShow, setIsErrorModalShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [requestResult, setRequestResult] = useState({
        completed: false
    });

    const [isResultModalVisible, setIsResultModalVisible] = useState(false);

    const saveFile = (e) => {

        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    const processWithAi = async (e) => {

        if(isRequestInProgress)
        {
            return;
        }

        if (typeof file === 'undefined') {
            return;
        }

        setIsRequestInProgress(true);

        let isMounted = true;
        const controller = new AbortController();

        const sendRequest = async () => {

            try {
                const formData = new FormData();
                formData.append("formFile", file);
                formData.append("formName", fileName);

                const res = await axiosPrivate.post(`/production/preload`,
                    formData,
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true,
                        signal: controller.signal
                    });

                if (isMounted) 
                {
                    console.log(res);

                    var entity = {...props.entity};

                    entity.orderNumber.value = res.data.orderNumber.value;
                    entity.orderNumber = props.validateProperty(entity.orderNumber);
                    entity.orderNumber.isAi = true;

                    if(res.data.dataOrdine.value != '')
                    {
                        entity.dataOrdine.value = new Date(res.data.dataOrdine.value);
                        entity.dataOrdine = props.validateProperty(entity.dataOrdine);
                        entity.dataOrdine.isAi = true;
                    }

                    // if(entity.customer.value != res.data.customer.value)
                    // {
                        entity.customer.value = res.data.customer.value;
                        entity.customer = props.validateProperty(entity.customer);
                        entity.customer.isAi = true;
                    //}

                    var selectItem = entity.articles.find((item) => (""+item.value) === res.data.articleId.value);
                    
                    //if (selectItem !== undefined && selectItem !== null) {
                        entity.articleId.value = selectItem;
                        entity.articleId = props.validateProperty(entity.articleId);
                        entity.articleId.isAi = true;
                     // }

                    entity.articleCode.value = res.data.articleCode.value;
                    entity.articleCode = props.validateProperty(entity.articleCode);
                    entity.articleCode.isAi = true;

                    entity.article.value = res.data.article.value;
                    entity.article = props.validateProperty(entity.article);
                    entity.article.isAi = true;

                    entity.color.value = res.data.color.value;
                    entity.color = props.validateProperty(entity.color);
                    entity.color.isAi = true;

                    entity.spessore.value = res.data.spessore.value;
                    entity.spessore = props.validateProperty(entity.spessore);
                    entity.spessore.isAi = true;

                    entity.numeroLotto.value = res.data.numeroLotto.value;
                    entity.numeroLotto = props.validateProperty(entity.numeroLotto);
                    entity.numeroLotto.isAi = true;

                    entity.piediQuadri.value = res.data.piediQuadri.value;
                    entity.piediQuadri = props.validateProperty(entity.piediQuadri);
                    entity.piediQuadri.isAi = true;

                    entity.quantityUM.value = res.data.quantityUM.value;
                    entity.quantityUM = props.validateProperty(entity.quantityUM);
                    entity.quantityUM.isAi = true;

                    entity.leathersNumber.value = res.data.leathersNumber.value;
                    entity.leathersNumber = props.validateProperty(entity.leathersNumber);
                    entity.leathersNumber.isAi = true;

                    //Ordini Cliente
                    res.data.customerOrderRows.forEach((row) => {
                        row.articleName.isAi = true;
                        row.customerCode.isAi = true;
                        row.articleColor.isAi = true;
                        row.deliveryDate.isAi = true;
                        row.quantity.isAi = true;
                    });

                    entity.customerOrderRows = res.data.customerOrderRows;
                    
                    props.setEntity(entity);

                    setIsRequestInProgress(false);
                    setIsModalShow(false);
                }
            }
            catch (err) 
            {
                console.error(err);
                //setErrorMessage(err?.response?.statusText);
                 setErrorMessage(`${err?.response?.data?.message}`);
                
                setIsModalShow(false);
                setIsErrorModalShow(true);

                setIsRequestInProgress(false);
            }
        }

        sendRequest();
    };

    return <>

        <div
            className="btn btn-info m-5"
        onClick={(e) => setIsModalShow(true)}
        >
            <i className="fas fa-upload p-0"></i>
        </div>

        {/* START - Select File Modal */}
        <Modal show={isModalShow} onHide={()=>{ if(!isRequestInProgress){setIsModalShow(false)}  }}>

            <Modal.Header closeButton>
                <Modal.Title>Carica Documento</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">

                    <div className="col-12 mt-5">
                        <div className="alert alert-info">
                            Carica il PDF dell'ordine da elaborare
                        </div>
                    </div>

                    <div className="col-12 mt-5">
                        <div>
                            {/* <label for="formFile" class="form-label">Default file input example</label> */}
                            <input
                            disabled={isRequestInProgress}
                             className="form-control" type="file" id="formFile" accept=".pdf" onChange={saveFile} />
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={()=>{setIsModalShow(false)}} disabled={isRequestInProgress}>Annulla</Button>

                <div
                    className="btn btn-info m-5"
                    onClick={(e)=> processWithAi()}
                    data-kt-indicator={isRequestInProgress ? "on" : "off"}>
                    <span className="indicator-label">Elabora con IA</span>
                    <span className="indicator-progress" >Sto elaborando
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </div>

            </Modal.Footer>
        </Modal>
        {/* END - Select File Modal */} 

        {/* START - Error Modal */}
        <ErrorModal 
            show={isErrorModalShow} 
            onHide={()=>{setIsErrorModalShow(false) }} 
            message={errorMessage}
            />
        {/* END - Error Result Modal */}         

    </>;
}


function ErrorModal(props) {

    return <>
    
    <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Esito Operazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="row d-flex justify-content-center">

                            <div className="d-flex flex-center flex-column py-10 px-10 px-lg-20">

                                <span className="svg-icon svg-icon-2hx svg-icon-danger mb-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                        <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="black"></path>
                                    </svg>
                                </span>

                                <div className="text-center">
                                    <h1 className="fw-bolder mb-5 text-danger">Errore</h1>
                                    <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                                    <div className="mb-9 text-dark" dangerouslySetInnerHTML={{ __html: props.message }}></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
    </>
}