import {useRef, useState, useEffect} from 'react';
import useAuth from '../../hooks/useAuth';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {executeLogin} from '../../services/LoginService';

// import axios from '../api/axios';
// const LOGIN_URL = '/users/authenticate';

const Login = () =>
{
    const { setAuth, persist, setPersist } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const[user, setUser] = useState('');
    const[pwd, setPwd] = useState('');
    const[errMsg, setErrMsg] = useState('');
    const[showIndicatorProgress, setShowIndicatorProgress] = useState('');

    useEffect(()=>{
        userRef.current.focus()
    }, [])

    useEffect(()=>{
        setErrMsg('')
    }, [user, pwd])

    const handleSubmit = async (e) =>{
        e.preventDefault();

        //Nel caso una richiesta di login sia già in corso non ne viene inoltrata un'altra
        if(!isLoadingLogin())
        {
            try
            {
                //mostra l'icona di caricamento e inibisce ulteriori richieste di login
                setLoadingLogin(true);

                const loginResult = await executeLogin(user, pwd);

                if(loginResult.errorCode == 0)
                {
                    const accessToken = loginResult.accessToken;
                    const roles = loginResult.roles;
                    const username = loginResult.username;
                    const userId = loginResult.userId;
                    const frontEndConfig = loginResult.frontEndConfig

                   setAuth({userId, username, roles, accessToken, frontEndConfig});
       
                   setUser('');
                   setPwd('');            
                   setLoadingLogin(false);
   
                   //E' stata commentata la navigazione alla pagina da cui si proviene per un comportamento anomalo del sistema
                   //Ovvero, se si esegue il login come amministratore e si va nella gestione degli utenti, quando si esegue il logout l'ultima pagina visitata rimane appunto la gestione utenti
                   //Che è accessibile solo agli amministratori. Se successivamente esegue il login un operatore, che non ha accesso a quella pagina, dopo aver eseguito il login, viene automaticamente
                   //reindirizzato all'ultima pagina visitata che è appunto quella della gestione utenti, a cui però non ha abbastanza privilegi per poterla visualizzare, quindi il sistema lo rimanda alla pagina di login.
                   navigate(from, {replace: true});

                   //navigate("/home");
                }
                else
                {
                    setErrMsg(loginResult.errorMessage);
                    setLoadingLogin(false);
        
                    errRef.current.focus();
                }
            } 
            catch(err)
            {
                setErrMsg(err);
            }
        }          
    }

    const togglePersist = () => {
        setPersist(prev => !prev);        
    }

    useEffect(() => {
        localStorage.setItem("persist", persist);
    }, [persist])

    const setLoadingLogin = (isLoading) => {
        if(isLoading)
        {
            setShowIndicatorProgress('on');            
        }
        else
        {
            setShowIndicatorProgress('off');
        }       
    }

    const isLoadingLogin = () => {
        let output = false;

       if (showIndicatorProgress == "on")
       {
            output = true;
       }

       return output;
    }    


    return (
            <section>
			<div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
				<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
					<a className="mb-12">
						{/* <img alt="Logo" src="/logo.svg" className="h-40px" /> */}
						{/* <img alt="Logo" src="/logo_full_carisma.jpg" /> */}
					</a>
					<div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
						<form 
                            className="form w-100" 
                            noValidate="novalidate" 
                            id="kt_sign_in_form" 
                            onSubmit={handleSubmit}>
							<div className="text-center mb-10">
                            <img alt="Logo" src="/media/logos/logo-login-V2.png" className="w-50" />
								{/* <h1 className="text-dark mb-3">Log In</h1> */}
							</div>
							<div className="fv-row mb-10">
                                <div className="d-flex flex-stack mb-2">
                                    <label className="form-label fs-6 fw-bolder text-dark">Username</label>
                                </div>                                    
                                
                                <input 
                                    className="form-control form-control-lg form-control-solid" 
                                    type="text" 
                                    id="username" 
                                    ref={userRef}
                                    autoComplete="off" 
                                    onChange={(e) => setUser(e.target.value)}
                                    value={user}
                                    required                                        
                                />

							</div>
							<div className="fv-row mb-10">
								<div className="d-flex flex-stack mb-2">
									<label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
								</div>
								<input 
                                    className="form-control form-control-lg form-control-solid" 
                                    type="password" 
                                    id="password" 
                                    autoComplete="off"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required                                    
                                />
							</div>
                            <div className="fv-row mb-10">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" value="" 
                                        id="persist"
                                        onChange={togglePersist}
                                        checked={persist}
                                        />

                                    <label className="form-check-label" htmlFor="persist">
                                        Mantieni l'accesso
                                    </label>
                                </div>                                
                            </div>
							<div className="text-center">
								<button type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5" data-kt-indicator={showIndicatorProgress}>
									<span className="indicator-label">Log In</span>
									<span className="indicator-progress" >Caricamento...
									    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
								</button>
                                <p ref={errRef} className={errMsg ? "alert alert-danger d-flex align-items-center" : "offscreen"} aria-live="assertive">{errMsg}</p>                                
							</div>
						</form>
					</div>
				</div>
			</div>
            </section>
    )
}

export default Login