import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { usePageData } from '../../../_metronic/layout/core';
import { useModuleActive } from '../../../hooks/useModuleActive';
import useAuth from '../../../hooks/useAuth';
import { MODULE_IDS } from '../../../models/systems/ModuleIds';
import { isAdmin, isSupervisor } from '../../utility/SystemFunctions';

import { Spinner } from 'react-bootstrap';
import clsx from 'clsx';

const NavHome = () => {

    const isModuleActive = useModuleActive();
    const authObj = useAuth();


    const { setPageTitle } = usePageData();
    const { setPageBreadcrumbs } = usePageData();

    //Setting Page BreadCrumb
    useEffect(() => {
        setPageTitle("Home");
        setPageBreadcrumbs([]);
    }, [])


    return (
        <>

    <div id="kt_content_container" className="mx-5 py-6">

                <section>
                    <div className="row gy-5 g-xl-8 mt-5">

                        {isModuleActive(MODULE_IDS.PRODUCTION_ORDER) && <>

                            {(isAdmin(authObj) || isSupervisor(authObj)) && <>
                                <div className='col-xl-4 col-md-6 col-sm-12 m-auto'>
                                    <Link to={`/productionOrders`}>

                                        <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                            <div className='card-body border-0'>
                                                <div className="d-flex position-relative">
                                                    <img width="60" height="60" className="bg-white me-3" src="/media/icons/navigation/production-orders.svg" />
                                                    <div className="my-4 me-4 lh-sm">
                                                        <h3 className="mb-0">Cartellini</h3>
                                                        <small className="text-muted">Ordini di produzione</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </>}

                            <div className='col-xl-4 col-md-6 col-sm-12 m-auto'>
                                <Link to={`/units`}>

                                    <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                        <div className='card-body border-0'>
                                            <div className="d-flex position-relative">
                                                <img width="60" height="60" className="bg-white me-3" src="/media/icons/navigation/production.svg" />
                                                <div className="my-4 me-4 lh-sm">
                                                    <h3 className="mb-0">Reparti</h3>
                                                    <small className="text-muted">Reparti di produzione</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </>
                        }


                        {(isModuleActive(MODULE_IDS.INVENTORY) || isModuleActive(MODULE_IDS.INVENTORY_BOARD)) && <>

                            {(isAdmin(authObj) || isSupervisor(authObj)) && <> 
                                <div className='col-xl-4 col-md-6 col-sm-12 m-auto'>
                                    <Link to={`/inventory`}>

                                        <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                            <div className='card-body border-0'>
                                                <div className="d-flex position-relative">
                                                    <img width="60" height="60" className="bg-white me-3" src="/media/icons/navigation/inventory.svg" />
                                                    <div className="my-4 me-4 lh-sm">
                                                        <h3 className="mb-0">Magazzino</h3>
                                                        <small className="text-muted">Magazzino materie prime</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </>}
                          
                        </>}

                        {isModuleActive(MODULE_IDS.CHEMICALS) && <>

                            {(isAdmin(authObj) || isSupervisor(authObj)) && <>
                                <div className='col-xl-4 col-md-6 col-sm-12 m-auto'>
                                    <Link to={`/chemicals/product/list`}>

                                        <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                            <div className='card-body border-0'>
                                                <div className="d-flex position-relative">
                                                    <img width="60" height="60" className="bg-white me-3" src="/media/icons/navigation/chemicals.svg" />
                                                    <div className="my-4 me-4 lh-sm">
                                                        <h3 className="mb-0">Chimico (Preview)</h3>
                                                        <small className="text-muted">Prodotti chimici</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </>}
                        </>}


                        <div className='col-xl-4 col-md-6 col-sm-12 m-auto'>
                            <Link to={`/interconnections`}>
                                <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                    <div className='card-body border-0'>
                                        <div className="d-flex position-relative">
                                            <img width="60" height="60" className="bg-white me-3" src="/media/icons/navigation/interconnections.svg"/>
                                            <div className="my-4 me-4 lh-sm">
                                                <h3 className="mb-0">Interconnessioni</h3>
                                                <small className="text-muted">Industria 4.0</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        {isModuleActive(MODULE_IDS.PRODUCTION_ORDER) && <>

                            {isAdmin(authObj) && <>
                                <div className='col-xl-4 col-md-6 col-sm-12 m-auto'>
                                    <Link to={`/profiles/article/leather/list`}>

                                        <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                            <div className='card-body border-0'>
                                                <div className="d-flex position-relative">
                                                    <img width="60" height="60" className="bg-white me-3" src="/media/icons/navigation/profiles.svg" />
                                                    <div className="my-4 me-4 lh-sm">
                                                        <h3 className="mb-0">Anagrafiche</h3>
                                                        <small className="text-muted">Articoli, Clienti e Fornitori</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </>}
                        </>
                        }

                        {isAdmin() && <>

                            <div className='col-xl-4 col-md-6 col-sm-12 m-auto'>
                                <Link to={`/settings/users`}>

                                    <div className='card hoverable mb-xl-8 shadow p-2 m-3'>
                                        <div className='card-body border-0'>
                                            <div className="d-flex position-relative">
                                                <img width="60" height="60" className="bg-white me-3" src="/media/icons/navigation/settings.svg" />
                                                <div className="my-4 me-4 lh-sm">
                                                    <h3 className="mb-0">Impostazioni</h3>
                                                    <small className="text-muted">Impostazioni di sistema</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </>}
                    </div>
                </section>
            </div>
        </>

    )
}

export default NavHome