import ROLES from '../system/Roles';

// ✅ Named function returning array of strings
export function isAdmin(authObj) {

    let output = false;
  
    authObj?.auth?.roles?.forEach(function (role) {
      
      if(role == ROLES.Amministratore)
      {
        output = true;
      }
  
    });
  
    return output;
  
}

  export function isSupervisor(authObj) {

    let output = false;
  
    authObj?.auth?.roles?.forEach(function (role) {
      
      if(role == ROLES.Supervisore)
      {
        output = true;
      }
  
    });
  
    return output;
  
  }
  