
export function isEditMode(location) 
{
    var output = false;

    if(location.pathname.indexOf("/edit") > -1)
    {
        output = true;
    }
   
    return output;
}


export function isViewMode (location)
{
    var output = false;

    if (location.pathname.indexOf("/view") > -1) 
    {
        output = true;
    }
   
    return output;
}    

export function isCreateMode(location)
{
    var output = false;

    if(location.pathname.indexOf("/create") > -1)
    {
        output = true;
    }
   
    return output;
}     


export function validateProperty (property)
{
    //Validazione
    if (property.isMandatory) {
        var propValue = "";

        if (property.dataType == "Date") {
            propValue = property.value;
        }
        else {
            propValue = property.value?.trim();
        }

        if (propValue == "" || propValue == null) {
            property.isError = true;
            property.errorMessage = "Il campo non può essere vuoto";
        }
        else {
            property.isError = false;
            property.errorMessage = "";
        }
    }

    return property;
}

//Validazione completa dell'oggetto Lavorazione
export function validateWorkCycle(entity) 
{
    entity.position = validateProperty(entity.position);
    entity.productionOrderNumber = validateProperty(entity.productionOrderNumber);
    entity.selection = validateProperty(entity.selection);
    entity.supplier = validateProperty(entity.supplier);
    entity.quantity = validateProperty(entity.quantity);
    entity.exitDate = validateProperty(entity.exitDate);
    entity.subcontractor = validateProperty(entity.subcontractor);
    entity.palletNumber = validateProperty(entity.palletNumber);

    return entity;
}

export function checkValidationErrors (entity)
{
    var output = false;

    if(
        entity.position.isError
    || entity.productionOrderNumber.isError
    || entity.selection.isError
    || entity.supplier.isError
    || entity.quantity.isError
    || entity.exitDate.isError
    || entity.subcontractor.isError
    || entity.palletNumber.isError
    || entity.notes.isError
    )
    {
        output = true;
    }

    return output;
}

export function normalizeEntity(entityModel) 
{
    if(entityModel.exitDate.value != "")
    {
      entityModel.exitDate.value = new Date(entityModel.exitDate.value);
    }

    return entityModel;
}