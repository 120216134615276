export const MODULE_IDS = {
    PRODUCTION: 'moduleProduction',
    PRODUCTION_ORDER: 'moduleProductionOrder',
    PACKING_LIST: 'modulePackingList',
    INVENTORY : 'moduleInventory',
    INVENTORY_BOARD : 'moduleInventoryBoard',
    CHEMICALS: 'moduleChemicals',
    CUSTOMER_ORDER_LITE: 'moduleCustomerOrderLite',
    PRELOAD_PROD_ORDER: 'modulePreloadProdOrder',
    QUANITTY_CONTROL: 'moduleQuantityControl',
  };