import clsx from 'clsx'
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { KTSVG } from '../../../_metronic/helpers'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { usePageData } from '../../../_metronic/layout/core';
import useCache from '../../../hooks/useCache';
import { Spinner, Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it"; // the locale you want
import { PageErrorMessage, MobileItemCard, MobilePagination } from "./Components";

registerLocale("it", it); // register it with the name you want

const InventoryBoardItemList = () => {
  const [workCycles, setWorkCycles] = useState();

  let entityType = "boardItem";
  let navPrefix = "inventory";

  //const [modalShow, setModalShow] = useState();  
  //const [modalMessage, setModalMessage] = useState();  

  const [isError, setIsError] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  let { idMacchina } = useParams();

  const { setPageTitle, pageMachines } = usePageData();
  const { setPageBreadcrumbs, pageBreadcrumbs } = usePageData();
  const [machineCode, setMachineCode] = useState();

  const [modalFilterShow, setModalFilterShow] = useState(false);

  const [tempFilter, setTempFilter] = useState();
  const [showExportProgress, setShowExportProgress] = useState('off');


  //---START Gestione Filtri---//
  const { filters } = useCache();
  const [filter, setFilter] = useState({});
  let emptyFilter = {
    selection: '',
    supplier: '',
    subcontractor: '',
    sortField: 'Position',
    sortDirection: 'desc'
  };
  //---END Gestione Filtri---//

  //Pagination Handling
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  //Sorting handling
  const [defaultSortAsc, setDefaultSortAsc] = useState(false);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState(1);

  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);

  const [modalShow, setModalShow] = useState();
  const [modalMessage, setModalMessage] = useState();
  const [saveInProgress, setSaveInProgress] = useState();
  const [showIndicatorProgress, setShowIndicatorProgress] = useState('');
  const [saveResult, setSaveResult] = useState();

  const paginationComponentOptions = {
    rowsPerPageText: 'Elementi per pagina',
    rangeSeparatorText: 'di',
    selectAllRowsItem: false
  };

  const goToNextPage = () => {

    var nextPage = currentPage + 1;
    getEntityList(nextPage, perPage, filter);

    setCurrentPage(nextPage);
  }

  const goToPrevPage = () => {

    var prevPage = currentPage - 1;
    getEntityList(prevPage, perPage, filter);

    setCurrentPage(prevPage);
  }

  const columns = [
    {
      name: 'Posizione',
      maxWidth: '120px',
      selector: row => row.position,
      sortable: true,
      sortFieldId: 1,
      sortField: 'Position',
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.position);
      }
    },
    {
      name: 'Num. Ordine',
      maxWidth: '130px',
      selector: row => row.productionOrderNumber,
      sortable: true,
      sortField: 'ProductionOrderNumber',
      sortFieldId: 2,
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.productionOrderNumber);
      }
    },
    {
      name: 'Scelta',
      maxWidth: '130px',
      selector: row => row.selection,
      sortable: true,
      sortField: 'Selection',
      sortFieldId: 3,
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.selection);
      }
    },
    {
      name: 'Fornitore',
      maxWidth: '130px',
      selector: row => row.supplier,
      sortable: true,
      sortField: 'Supplier',
      sortFieldId: 4,
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.supplier);
      }
    },
    {
      name: 'Quantità',
      maxWidth: '130px',
      selector: row => row.quantity,
      sortable: false,
      sortFieldId: 5,
      sortField: 'Quantity',
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.quantity);
      }
    },
    {
      name: 'Data Uscita',
      maxWidth: '190px',
      selector: row => row.exitDate,
      sortable: true,
      sortFieldId: 6,
      sortField: 'ExitDate',
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.exitDateStr);
      }
    },
    {
      name: 'Terzista',
      maxWidth: '150px',
      selector: row => row.subcontractor,
      sortable: true,
      sortFieldId: 7,
      sortField: 'Subcontractor',
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.subcontractor);
      }
    },
    {
      name: 'Num. Pedane',
      maxWidth: '130px',
      selector: row => row.palletNumber,
      sortable: false,
      sortFieldId: 8,
      sortField: 'PalletNumber',
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.palletNumber);
      }
    },
    {
      name: 'Note',
      selector: row => row.notes,
      sortable: false,
      sortFieldId: 8,
      sortField: 'Notes',
      cell: (row, index, column, id) => {
        return getProdViewLink(row?.id, row?.notes);
      }
    },
    {
      name: 'Azioni',
      maxWidth: '100px',
      right: true,
      ignoreRowClick: true,
      cell: (row, index, column, id) => {
        return (
          <div className='row'>

            <Link to={`/${navPrefix}/${entityType}/view/${row?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className="fas fa-eye"></i>
            </Link>

            <Link to={`/${navPrefix}/${entityType}/edit/${row?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
              <i className="fas fa-pencil-alt"></i>
            </Link>
          </div>)
      }
    }
  ];

  //Setting Page BreadCrumb
  useEffect(() => {

    setPageTitle("Magazzino");

    setPageBreadcrumbs(["Lavagna giacenze"]);
  }, [])


  useEffect(() => {

    let initFilter = {};

    //Se il filtro non esiste in cache, allora viene impostato quello vuoto da utilizzare e salvato in cache per la pagina corrente
    //Altrimenti viene preso il filtro in cache
    if ((typeof filters[location.pathname] === 'undefined')) {
      initFilter = emptyFilter;
    }
    else {
      initFilter = filters[location.pathname];
    }

    setTableFiltering(initFilter);

    getEntityList(1, perPage, initFilter);

  }, [])



  const getProdViewLink = (rowId, text) => {
    return <Link to={`/${navPrefix}/${entityType}/view/${rowId}`} className='text-dark text-hover-primary fs-6'>{text}</Link>;
  }

  const handleSort = async (column, sortDirection) => {

    if (sortDirection == "asc" || sortDirection == "") {
      setDefaultSortAsc(true);
    }
    else {
      setDefaultSortAsc(false);
    }

    setDefaultSortFieldId(column.sortFieldId);

    let sortedFilter = { ...filter, sortField: column.sortField, sortDirection, sortDirection };
    setTableFiltering(sortedFilter);

    getEntityList(1, perPage, sortedFilter);
  };

  const handleFilterClose = () => {

    //Se la finestra viene chiusa senza ne applicare ne resettare il filtro, allora i filtri impostati dal momento dell'apertura 
    //della finestra del filtro vengono persi e reimpostati quelli precedentemente applicati
    setFilter(tempFilter);

    setModalFilterShow(false);
  }

  const showFilterModal = () => {

    //Salvataggio in memoria del filtro attuale. 
    //Questo viene fatto perchè se non viene applicato o resettato, il filtro viene reimpostato
    //A quello al momento dell'apertura della finestra

    setTempFilter(filter);

    setModalFilterShow(true);
  }

  const resetFilter = () => {

    setTableFiltering(emptyFilter);
    getEntityList(1, perPage, emptyFilter);

    setModalFilterShow(false);
  }

  const applyFilter = () => {
    setTableFiltering(filter);
    getEntityList(1, perPage, filter);
    setModalFilterShow(false);
  }

  const setFilterProperty = (attribute, value, event) => {

    if (attribute == "id") {
      if (event.target.validity.valid) {
        setFilter({ ...filter, id: value });
      }
    }
    else {
      var tmpFilter = { ...filter }
      tmpFilter[attribute] = value;
      setFilter(tmpFilter);

    }
    // else if (attribute == "code") 
    // {
    //     setFilter({...filter, code:value});
    // }      
    // else if (attribute == "name") 
    // {
    //     setFilter({...filter, name:value});
    // }      
  }

  const getEntityListRequest = (page, pageSize, pageFilter) => {

    let entityListRequest =
    {
      idMacchina: idMacchina,
      page: page,
      pageSize: pageSize,
      filterSelection: pageFilter.selection,
      filterSupplier: pageFilter.supplier,
      filterSubcontractor: pageFilter.subcontractor,
      sortField: pageFilter.sortField,
      sortDirection: pageFilter.sortDirection
    }

    return entityListRequest;
  }

  const getEntityList = (page, pageSize, pageFilter) => {

    setIsLoading(true);

    let isMounted = true;
    const controller = new AbortController();

    let entityListRequest = getEntityListRequest(page, pageSize, pageFilter);

    const fetchWorkCycleList = async () => {

      try {
        const response = await axiosPrivate.post(`/inventoryBoard/getEntities`,
          JSON.stringify(entityListRequest),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
            signal: controller.signal
          });

        if (isMounted) {
          setWorkCycles(response.data.data);
          setTotalRows(response.data.total);

          //L'elenco delle righe selezionate viene svuotato ogni volta che c'è un aggiornamento delle righe mostrate
          setSelectedRows([]);

          setIsLoading(false);
          setIsError(false);
        }
      }
      catch (err) {
        console.error(err);
        setErrorMessage(`${err}<br>${err?.response?.data?.message}`);
        setIsLoading(false);
        setIsError(true);
      }
    }

    fetchWorkCycleList();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }

  const handlePageChange = page => {
    getEntityList(page, perPage, filter);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {

    getEntityList(page, newPerPage, filter);
    setPerPage(newPerPage);
  };


  const onSelectedRowsChange = (state) => {

    var selectedRowsArray = [];

    state.selectedRows.forEach(function (tableRow) {
      selectedRowsArray.push(tableRow.id);
    });

    setSelectedRows(selectedRowsArray);
  };

  const handleConfirmationClose = () => {
    setConfirmationModalShow(false);
  }


  const setLoadingSave = (isLoading) => {
    if (isLoading) {
      setShowIndicatorProgress('on');
    }
    else {
      setShowIndicatorProgress('off');
    }
  }

  const handleClose = () => {
    if (saveResult == true) {
      setModalShow(false);
      getEntityList(1, perPage, filter);
    }
    else {
      setModalShow(false);
    }
  }

  //Dato che il filtro in cache è stato introdotto successivamente, viene fatta una doppia operazione
  //Sia sul filtro in cache che in quello locale. Quindi è stato raggruppato nello stesso medoto
  const setTableFiltering = (inputFilter) => {
    filters[location.pathname] = inputFilter;
    setFilter(inputFilter);
  }

  const isFilterActive = () => {

    var output = false;

    if ((typeof filters[location.pathname] === 'undefined')) {
      output = false;
    }
    else {
      var currentFilter = filters[location.pathname];

      if (currentFilter.selection == ""
        && currentFilter.supplier == ""
        && currentFilter.subcontractor == "" 
      ) {
        output = false;
      }
      else {
        output = true;
      }
    }
    return output;
  }

  return (
    <div id="kt_content_container" className="mx-5">

      {/* begin::Spinner */}
      {isLoading &&
        <div className="row d-flex justify-content-center m-20">
          <Spinner animation="border" variant="primary" />
        </div>
      }
      {/* end::Spinner */}

      {/* begin::Error message */}
      {!isLoading && isError &&
        <PageErrorMessage message={errorMessage} />
      }
      {/* end::Error message */}

      {/* begin::Table */}
      {!isLoading && !isError &&
        <>
          <div className="row d-none d-md-block">
            <div className='card mb-5 mt-5 mb-xl-8'>
              {/* begin::Header */}
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Elenco Giacenze</span>
                </h3>
                <div className="card-toolbar">

                  <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                  <span className="svg-icon svg-icon-1">
                        <i className={clsx('fas fa-search', isFilterActive() ? 'text-warning' : '')}></i>
                      </span>
                      <span className={clsx(isFilterActive() ? 'text-warning' : '')}>Filtro</span>
                  </div>

                  <div className='btn btn-secondary me-2 mt-1' onClick={(e) => getEntityList(1, perPage, filter)}>
                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-sync"></i>
                    </span>
                    Aggiorna
                  </div>

                  <Link to={`/${navPrefix}/boardItem/create/0`} className='btn btn-success mt-1'>
                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-solid fa-plus"></i>
                    </span>
                    Nuovo
                  </Link>

                </div>
              </div>
              {/* end::Header */}
              {/* begin::Body */}
              <div className='card-body py-3'>

                <div className='row mb-2'>

                  <DataTable
                    columns={columns}
                    data={workCycles}
                    pagination
                    paginationServer

                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationDefaultPage={currentPage}
                    paginationPerPage={perPage}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}

                    onSort={handleSort}
                    sortServer
                    defaultSortFieldId={defaultSortFieldId}
                    defaultSortAsc={defaultSortAsc}

                    highlightOnHover
                    striped
                  />
                </div>
              </div>
            </div>

          </div>



          <div className="row d-block d-md-none" >

            <div className="col-12">
              <div className='card mb-5 mt-5 mb-xl-8 shadow'>
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Elenco Giacenze</span>
                  </h3>
                  <div className="card-toolbar">

                    {/* <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                    <span className="svg-icon svg-icon-1">
                      <i className="fas fa-search"></i>
                    </span>
                    Filtro
                  </div> */}

                    <div className='btn btn-primary me-2 mt-1' onClick={(e) => showFilterModal()}>
                      <span className="svg-icon svg-icon-1">
                        <i className={clsx('fas fa-search', isFilterActive() ? 'text-warning' : '')}></i>
                      </span>
                      <span className={clsx(isFilterActive() ? 'text-warning' : '')}>Filtro</span>
                    </div>
                  </div>
                </div>

              </div>

            </div>

            {workCycles?.map((wc, i) =>
              <>
                <div className="col-12" key={i}>
                  <MobileItemCard item={wc} />
                </div>
              </>)
            }

            <MobilePagination
              currentPage={currentPage}
              perPage={perPage}
              totalRows={totalRows}
              onGoToPrevPage={goToPrevPage}
              onGoToNextPage={goToNextPage} />

          </div>
        </>
      }

      <Modal show={modalFilterShow} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filtri di Ricerca</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">

            <div className="row">
              <div className="mb-10 col-12">

                <label className="form-label">Scelta</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Scelta"
                  value={filter?.selection}
                  onChange={(e) => setFilterProperty('selection', e.target.value, e)}
                />
              </div>

              <div className="mb-10 col-12">

                <label className="form-label">Fornitore</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Fornitore"
                  value={filter?.supplier}
                  onChange={(e) => setFilterProperty('supplier', e.target.value, e)}
                />
              </div>

              <div className="mb-10 col-12">

                <label className="form-label">Terzista</label>
                <input type="text"
                  className='form-control form-control-solid'
                  placeholder="Terzista"
                  value={filter?.subcontractor}
                  onChange={(e) => setFilterProperty('subcontractor', e.target.value, e)}
                />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetFilter}>
            Reset
          </Button>
          <Button variant="primary" onClick={applyFilter}>
            Applica
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default InventoryBoardItemList