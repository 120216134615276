import axios from 'axios';

//Determina come viene costruito l'endpoint per raggiungere il backend
const APPLICATION_ENVIROMENT = process.env.REACT_APP_APPLICATION_ENVIROMENT;

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const API_ENDPOINT_PORT = process.env.REACT_APP_API_ENDPOINT_PORT;
const API_ENDPOINT_PROTOCOL = process.env.REACT_APP_API_ENDPOINT_PROTOCOL;
const API_ENDPOINT_APP_NAME = process.env.REACT_APP_API_ENDPOINT_APP_NAME;

const GetUrl = () => {

    let output = "";

    //let protocol = "https";
    let protocol = API_ENDPOINT_PROTOCOL;
    let hostname = window.location.hostname;

    //Nel caso di ambiente cloud il dominio del backend viene generato a partire da quello del frontend
    //Altrimenti in ambiente locale viene costruito diversamente

    if( !(typeof(APPLICATION_ENVIROMENT) === 'undefined') && APPLICATION_ENVIROMENT == "CLOUD")
    {
        hostname = GetCloudApiUrl();
    }
    else
    {
        if (!(typeof (API_ENDPOINT) === 'undefined') && API_ENDPOINT != "") {
            hostname = API_ENDPOINT;
        }
    }

    if (!(typeof (API_ENDPOINT_PORT) === 'undefined') && API_ENDPOINT_PORT != "") {
        hostname = hostname + ":" + API_ENDPOINT_PORT;
    }

    if (!(typeof (API_ENDPOINT_APP_NAME) === 'undefined') && API_ENDPOINT_APP_NAME != "") {
        hostname = hostname + "/" + API_ENDPOINT_APP_NAME;
    }

    output = protocol + "://" + hostname;

    return output;

}

const GetCloudApiUrl = () => 
{
    let output = "";

    let frontendHostname = window.location.hostname;

    // Verifica che il dominio sia valido
    if (frontendHostname.endsWith('.easymes.cloud')) 
    {
        // Split della stringa in base ai punti
        const parts = frontendHostname.split('.');

        // Aggiunta "api" dopo la prima parte
        if (parts.length >= 3) 
        {
            parts.splice(1, 0, 'api');
            output = parts.join('.');
        } 
        else 
        {
            throw new Error('Formato del dominio non valido.');
        }
    }
    else
    {
        throw new Error('Dominio non valido. Deve terminare con .easymes.cloud');
    }

    return output;
}

export default axios.create({
    baseURL: GetUrl()
});

export const axiosPrivate = axios.create({
    baseURL: GetUrl(), 
    headers: {'Content-Type': 'application/json'}, 
    withCredentials: true
});